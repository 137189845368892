<template>
  <div
    id="app"
    :class="[
      themeClass,
      appClass,
      {
        'hide-header': hideHeader,
        'route-is-user-journey': routeIsUserJourney,
        'background-if-no-route-name': !$route || !$route.name,
      },
    ]"
  >
    <AppHeader
      :hide-header="hideHeader"
      :hide-avatar="hideAvatar"
    />
    <div
      :class="{ 'is-image': Boolean(backgroundURLAndCssStyle.url), 'route-is-user-journey': routeIsUserJourney }"
      :style="[backgroundURLAndCssStyle.cssStyle]"
      class="background-placeholder"
    />
    <!-- this img is a helper, only needed because "background-image" does not fire a "onerror" event -->
    <img
      v-if="backgroundURLAndCssStyle.url"
      :src="backgroundURLAndCssStyle.url"
      style="display: none;"
      @error="errorLoadingBackgroundImage"
    >

    <!---->
    <!--    <div class="error-page-background-preload" />-->
    <!---->

    <!--Hotspot to show the background so the user can see what he’s working for.-->
    <div
      v-if="haveHotspotToShowBackground"
      class="hotspot-to-show-background"
      @mouseover="mouseAtShowBackgroundHotspot = true"
      @mouseleave="mouseAtShowBackgroundHotspot = false"
    />

    <RouterView
      id="scrollable-router-view"
      :class="[routerViewClasses, { 'hide-to-show-background': mouseAtShowBackgroundHotspot }]"
      :style="{ '--scrollbarWidth': scrollbarWidth }"
    />

    <!--    <Transition name="fade">-->
    <!--      <div-->
    <!--        v-if="mouseAtShowBackgroundHotspot"-->
    <!--        class="hidden-message"-->
    <!--      >-->
    <!--        <div>-->
    <!--          Hello, this is a hidden message!-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </Transition>-->

    <div
      v-if="globalStore.showDebugButtons"
      class="debug debug-themes-and-roles"
    >
      <!---->
      <!---->
      <!-- Debug themes -->
      <!---->
      <!---->
      <!--      <BDropdown-->
      <!--        v-if="globalStore.isAppImp || accountStore.userHasAdministratorRole || globalStore.isDevelopmentEnvironment"-->
      <!--        :text="`Theme: ${globalStore.availableThemes.find(theme => theme.id === globalStore.selectedThemeId).name}`"-->
      <!--        class="debug-themes mr-gap-0_38x"-->
      <!--      >-->
      <!--        <BDropdownItem-->
      <!--          v-for="theme in globalStore.availableThemes"-->
      <!--          :key="theme.id"-->
      <!--          :active="globalStore.selectedThemeId === theme.id"-->
      <!--          @click="globalStore.selectTheme(theme.id)"-->
      <!--        >-->
      <!--          {{ theme.name }}-->
      <!--        </BDropdownItem>-->
      <!--      </BDropdown>-->

      <!---->
      <!---->
      <!-- Debug roles -->
      <!---->
      <!---->
      <BDropdown
        v-if="authStore.isAuthenticated"
        :text="debugRoleDropdownText"
        right
      >
        <!-- ^ z-index 1031 if above header -->
        <BDropdownItem
          disabled
          style="pointer-events: none;"
        >
          <span style="color: #009be0">
            /!\ For debugging /!\
          </span>
        </BDropdownItem>
        <BDropdownItem
          v-for="role in accountStore.allRoles"
          :key="role.code"
          :active="accountStore.userRoleId === role.id"
          @click="accountStore.setLocalUserRole(role)"
        >
          {{ role.id }} {{ role.name }}
        </BDropdownItem>
      </BDropdown>
    </div>

    <TimeZoneMismatchModal />

    <!---->
    <div
      v-if="!(globalStore.isProduction || globalStore.forceHideDebugButtons)"
      class="environment-and-version"
    >
      {{ environmentAndVersion }}
    </div>
    <div
      v-if="!globalStore.isProduction"
      :class="{ transparent: globalStore.forceHideDebugButtons }"
      class="debug-page-button"
      @click="openDebugModal"
    />
    <div
      v-if="!globalStore.isProduction"
      v-tooltip="`${globalStore.forceHideDebugButtons ? 'Show' : 'Hide'} debug buttons ${globalStore.forceHideDebugButtons ? '' : '(override)'}`"
      :class="{ transparent: globalStore.forceHideDebugButtons }"
      class="debug-toggle-force-hide-debug-buttons"
      @click="globalStore.toggleForceHideDebugButtons"
    >
      👁
    </div>
    <BModal
      v-if="!globalStore.isProduction"
      v-model="isDebugModalVisible"
      teleport-disabled
      size="sm"
      centered
      no-footer
    >
      <template #header>
        Debug
      </template>

      <template #default>
        <AppButtonWithLed
          label="Enter Talent Manual Journey (demo mode)"
          @click="enterUserJourneyTalentManualDemoMode(1, 0)"
        />
        <AppButtonWithLed
          v-if="globalStore.isAppImp || (globalStore.isAppJgo && globalStore.isThemeV3) || globalStore.isAppTly || globalStore.isAppZae"
          label="Enter GetReady Program (demo mode)"
          class="mt-gap-1x"
          @click="enterUserJourneyV3DemoMode"
        />
        <AppButtonWithLed
          :label="`${globalStore.forceHideDebugButtons ? 'Show' : 'Hide'} debug buttons ${globalStore.forceHideDebugButtons ? '' : '(override)'}`"
          class="mt-gap-1x"
          @click="globalStore.toggleForceHideDebugButtons"
        />
      </template>
    </BModal>
    <!---->
    <!--    <NotificationPromptModal />-->
    <!---->
  </div>
</template>

<script
  setup
  lang="ts"
>
import { type StyleValue, computed, inject, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { AxiosResponse, AxiosStatic } from 'axios';
import type { ToastObject } from 'node_modules/@hoppscotch/vue-toasted/dist';
import { defineRule } from 'vee-validate';
import { isAfter } from 'date-fns';

import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';
import { useImpulsesStore } from '@/stores/impulses';
import { useTranslationStore } from '@/stores/translation';
import { useToastsStore } from '@/stores/toasts';

// import { useToast } from '@/composables/useToast';

import AppHeader from '@/components/AppHeader.vue';
import AppButtonWithLed from '@/components/misc/AppButtonWithLed.vue';
import TimeZoneMismatchModal from '@/components/misc/TimeZoneMismatchModal.vue';
// import NotificationPromptModal from '@/components/misc/NotificationPromptModal.vue';

// const $router = useRouter();
const $route = useRoute();
const $http: undefined | AxiosStatic = inject('$http');
const $log: any = inject('$log');
const accountStore = useAccountStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const impulsesStore = useImpulsesStore();
const translationStore = useTranslationStore();
const toastsStore = useToastsStore();
// const toast = useToast();

const routesWithoutCustomBackground = [
  'Invitation',
  'OnboardingPhase1',
  'OnboardingPhase2',
  'OnboardingPhase3',
  'OnboardingPhase4',
  'OnboardingTalentManualPhase1',
  'OnboardingTalentManualPhase2',
  'OnboardingTalentManualPhase3',
  'OnboardingTalentManualPhase4',
  // 'UserJourneyV3',
];
const mouseAtShowBackgroundHotspot = ref(false);
const scrollbarWidth = ref('0px');
const autoTranslationOnEdgeDetected = ref(false);
const toastForBrowserAutoTranslationWarningFirstTime = ref<ToastObject | null>(null);
const toastForBrowserAutoTranslationWarning = ref<ToastObject | null>(null);
const isDebugModalVisible = ref(false);

const environmentAndVersion = computed(() => {
  if (globalStore.isProduction) {
    return '';
  }
  return `Environment: ${(window.ENV && window.ENV.ENVIRONMENT) || 'n/d'}     v${globalStore.currentVersion}  /  API ${globalStore.currentApiVersion}`;
});

const routeIsUserJourney = computed(() => {
  if (!$route || !$route.name) {
    return false;
  }
  return [
    'OnboardingPhase1',
    'OnboardingPhase2',
    'OnboardingPhase3',
    'OnboardingPhase4',
    'OnboardingTalentManualPhase1',
    'OnboardingTalentManualPhase2',
    'OnboardingTalentManualPhase3',
    'OnboardingTalentManualPhase4',
    'UserJourneyV3',
  ].includes($route.name.toString());
});

const hideHeader = computed(() => {
  if (!$route || !$route.name) {
    return true;
  }
  if ($route.matched
    && $route.matched.length
    && $route.matched[0].name === 'ParticipantOrPartnerWelcome') {
    return false; // show even if not authenticated
  }
  // /!\ User journey pages define their own header. /!\
  return !authStore.isAuthenticated
    || routeIsUserJourney.value
    || [
      'TermsPage',
      'Invitation',
      'EmailConfirmation',
      'PasswordReset',
      'SignIn', // defined on SignIn view
      'SignedOut', // defined on SignIn view
      'EmailTemplate',
      'EmailTemplate2',
    ].includes($route.name.toString());
});

const hideAvatar = computed(() => {
  if (!$route || !$route.name) {
    return true;
  }
  // if (!authStore.isAuthenticated
  //   && $route.matched
  //   && $route.matched.length
  //   && $route.matched[0].name === 'ParticipantOrPartnerWelcome') {
  //   return true; // do not show the avatar when not authenticated and seeing the welcome page
  // }
  return false;
});

const haveHotspotToShowBackground = computed(() => {
  const routeName = $route?.matched?.[0]?.name;
  if (!routeName) {
    return false;
  }

  const routeHasCustomBackground = !routesWithoutCustomBackground.includes(routeName.toString());
  return !hideHeader.value && routeHasCustomBackground;
});

const backgroundURLAndCssStyle = computed(() => {
  interface UrlAndStyle {
    url?: string | null;
    cssStyle: StyleValue;
  }

  const urlAndStyle: UrlAndStyle = {
    url: null,
    cssStyle: {},
  };

  if (!$route) {
    return urlAndStyle;
  }

  if (globalStore.isThemeV3) {
    urlAndStyle.cssStyle = {
      background: `var(--bg-theme-${globalStore.selectedThemeId})`,
    };
    return urlAndStyle;
  }

  if (globalStore.isAppTly || globalStore.isAppZae) {
    // Tly when not theme v3
    // No user/custom backgrounds
    urlAndStyle.cssStyle = {
      background: '#ffffff', // tly default
    };
    return urlAndStyle;
  }
  //
  //
  //
  if ($route.matched[0]
    && ($route.matched[0]?.name && !routesWithoutCustomBackground.includes($route.matched[0].name.toString()))
    && accountStore.currentBackground) {
    if ('title' in accountStore.currentBackground && 'image' in accountStore.currentBackground) {
      //
      // Background URL
      //
      urlAndStyle.url = `${accountStore.currentBackground.image}`;
      urlAndStyle.cssStyle = {
        '--bgUrl': `url(${accountStore.currentBackground.image})`,
      };
      return urlAndStyle;
    }
    if ('code' in accountStore.currentBackground) {
      //
      // Background object with id and code (color)
      //
      urlAndStyle.cssStyle = {
        background: accountStore.currentBackground.code,
      };
      return urlAndStyle;
    }
    if ('image' in accountStore.currentBackground) {
      //
      // User uploaded (remote URL, not local image)
      //
      urlAndStyle.url = accountStore.currentBackground.image;
      urlAndStyle.cssStyle = {
        '--bgUrl': `url(${accountStore.currentBackground.image})`,
      };
      return urlAndStyle;
    }
  }
  //
  // Return the default background
  //
  let background;
  if (globalStore.isAppJgo) {
    background = '#f4f1be';
  } else {
    background = '#F1F4F9'; // v3 background
  }
  urlAndStyle.cssStyle = {
    background,
  };
  return urlAndStyle;
});

const routerViewClasses = computed(() => {
  if (!$route || !$route.name) {
    return '';
  }
  const classes = ['router-view']; // absolute / with scroll below the header
  if (!(['Invitation', 'OnboardingPhase1', 'SignIn', 'SignedOut'].includes($route.name.toString()))) {
    classes.push('show-scrollbar');
  }
  return classes;
});

const debugRoleDropdownText = computed(() => {
  if (!accountStore.userRole) {
    return 'No user role';
  }
  return accountStore.userRole.name;
});

const themeClass = computed(() => {
  if (globalStore.isOldThemeV2) {
    return `theme-${globalStore.selectedThemeId}`;
  }
  // v3 is based on v2 (to avoid having to re-define everything on CSS); override v2 only when needed.
  return `theme-v2 theme-${globalStore.selectedThemeId}`;
});

const appClass = computed(() => {
  if (globalStore.isAppZly) {
    return 'app-imp app-zly';
  }
  if (globalStore.isAppZae) {
    return 'app-tly app-zae';
  }
  return `app-${globalStore.appName}`;
});

function checkWhichRoleToShowBasedOnActiveImpulses() {
  if (!impulsesStore.numberOfActiveImpulsesForEachRole) {
    return;
  }

  // Only change the role if on Impulse Area?
  // $log.debug(`${LOG_TAG} route:`, $route);
  if ($route.matched[0] && $route.matched[0].name !== 'ImpulseArea') {
    // Not impulse area
    $log.debug('There is a route and it’s not Impulse Area, skipping verification / role change', $route.matched);
    return;
  }

  const { IMP_TEAM_LEADER, IMP_TEAM_MEMBER } = accountStore.allRoles;
  const teamLeaderRoleId = IMP_TEAM_LEADER.id; // TODO: check type
  const teamMemberRoleId = IMP_TEAM_MEMBER.id; // TODO: check type
  const hasBothRoles = [teamLeaderRoleId, teamMemberRoleId].every((roleId) => Object.keys(impulsesStore.numberOfActiveImpulsesForEachRole).includes(roleId.toString()));

  if (!hasBothRoles) {
    // Does not have both roles, nothing to check
    return;
  }

  const changeRole = async (roleId: number, roleName: string) => {
    await accountStore.changeRoleIDServerSide(roleId);
    toastsStore.showToast({
      toastMessage: `You are now in the role of ${roleName}`,
      toastOptions: {
        type: 'success',
        icon: 'check-circle',
      },
    });
  };

  // Decide which role to show (the one with the newer impulse)
  $log.debug('activeImpulsesPerRole:', impulsesStore.numberOfActiveImpulsesForEachRole);
  const teamLeaderActiveImpulse: undefined | { pendingImpulsesCount: number; impulseDatetime: string } = impulsesStore.numberOfActiveImpulsesForEachRole[teamLeaderRoleId];
  const teamMemberActiveImpulse: undefined | { pendingImpulsesCount: number; impulseDatetime: string } = impulsesStore.numberOfActiveImpulsesForEachRole[teamMemberRoleId];

  if (teamLeaderActiveImpulse?.pendingImpulsesCount && teamMemberActiveImpulse?.pendingImpulsesCount) {
    // Both roles with active impulses, choose the newest impulse
    $log.debug('Both Team Leader and Team Member roles have an active impulse, choose the newest impulse');
    $log.debug('Team Leader active impulse:', teamLeaderActiveImpulse);
    $log.debug('Team Member active impulse:', teamMemberActiveImpulse);
    const teamLeaderImpulseIsNewer = isAfter(new Date(teamLeaderActiveImpulse.impulseDatetime), new Date(teamMemberActiveImpulse.impulseDatetime));

    if (teamLeaderImpulseIsNewer && !accountStore.userRoleIsImpTeamLeader) {
      // Team Leader impulse is newer, show it, if not already in that role
      $log.debug('Team Leader impulse is newer');
      changeRole(teamLeaderRoleId, 'Team Leader');
    } else if (!teamLeaderImpulseIsNewer && accountStore.userRoleId !== teamMemberRoleId) {
      // Team Member impulse is newer, show it
      $log.debug('Team Member impulse is newer');
      changeRole(teamMemberRoleId, 'Team Member');
    }
  } else if (teamLeaderActiveImpulse?.pendingImpulsesCount && !accountStore.userRoleIsImpTeamLeader) {
    // Only Team Leader role has an active impulse, and current role is not Team Leader, change to it
    $log.debug(`Only the Team Leader has an active impulse but this is not the correct role (${accountStore.userRoleId}): will change to role ${teamLeaderRoleId}`);
    changeRole(teamLeaderRoleId, 'Team Leader');
  } else if (teamMemberActiveImpulse?.pendingImpulsesCount && !accountStore.userRoleIsImpTeamMember) {
    // Only Team Member role has an active impulse, and current role is not Team Member, change to it
    $log.debug(`Only the Team Member has an active impulse but this is not the correct role (${accountStore.userRoleId}): will change to role ${teamMemberRoleId}`);
    changeRole(teamMemberRoleId, 'Team Member');
  }
}

watch(
  () => impulsesStore.numberOfActiveImpulsesForEachRole,
  () => {
    if (!globalStore.isAppImp) {
      return;
    }
    $log.debug('App: watch impulsesStore.numberOfActiveImpulsesForEachRole', impulsesStore.numberOfActiveImpulsesForEachRole);
    setTimeout(() => {
      // setTimeout to give time for the router to settle
      checkWhichRoleToShowBasedOnActiveImpulses();
    }, 1000);
  },
  { immediate: true },
);

function getScrollWidthForThisBrowser() {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  scrollbarWidth.value = `${scrollDiv.offsetWidth - scrollDiv.clientWidth}px`;

  // Delete the div
  document.body.removeChild(scrollDiv);
}

function listenForBrowserTranslationChanges() {
  if (!window.MutationObserver) {
    $log.debug('MutationObserver not supported');
    return;
  }
  const toastMessage = `
    <div class="d-flex flex-column mt-gap-0_38x mb-gap-0_38x">
      <div>
        You are using your browser translation tool to translate this page.
      </div>
      <div class="mt-gap-0_38x">
        For English and Deutsch you can use the page selector at the top of the page.
      </div>
    </div>
  `;
  const toastOptions = {
    // className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
    type: 'info',
    // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
    duration: 0,
    action: {
      text: '×',
      class: 'close-toast',
      onClick: (e: Event, toastObject: ToastObject) => {
        toastObject.goAway(0);
      },
    },
  };
  const observerOptions = {
    attributes: true, // this is to watch for attribute changes.
  };
  // Find the element that you want to "watch"
  // Chrome changes the "lang" attribute at the html element and adds a class "translated-ltr"
  // Edge changes the "lang" attribute too, but does not add any class (so let's compare the lang attribute with the selected system language)
  const targetChromeAndSafari = document.querySelector('html');
  const chromeAndSafariAttributeName = 'lang';
  // Edge adds a "_msthash" attribute to the translated elements; the title is one of them
  const targetEdge = document.querySelector('title');
  const edgeAttributeName = '_msthash';
  let lastLangDetected = ''; // prevent a loop on Safari, that triggers the observer without stopping
  //
  // create an observer instance for Chrome and Safari
  const observerChromeAndSafari = new MutationObserver((mutationRecords) => {
    /**
      this is the callback where you do what you need to do.
     The argument is an array of MutationRecords where the affected attribute is named "attributeName".
     There is a few other properties in a record but I'll let you work it out yourself.
     */
    const mutationRecord = mutationRecords.find((mr) => mr.attributeName === chromeAndSafariAttributeName);
    if (!mutationRecord || !mutationRecord.target) {
      // Not what we are looking for
      return;
    }
    // target will be <html>
    const lang = (mutationRecord.target as Element).attributes.getNamedItem(chromeAndSafariAttributeName);
    if (!lang || !lang.value) {
      return;
    }
    const simpleLang = lang.value.split('-')[0].toLowerCase();
    const simpleSystemLang = translationStore.localeCode.split('-')[0].toLowerCase();
    if (lastLangDetected === simpleLang) {
      return; // already detected; stop the loop on Safari
    }
    lastLangDetected = simpleLang;
    $log.debug(`Detected attribute ${chromeAndSafariAttributeName} mutation at <html> element`); // this happens on loop on Safari, let's not print anything
    // simpleLang !== simpleSystemLang for Safari
    // class="translated-ltr" for Chrome (and Chrome sets lang="auto" when the translation is turned off)
    if ((simpleLang !== 'auto' && simpleLang !== simpleSystemLang) || (mutationRecord.target as Element).classList.contains('translated-ltr')) {
      // Translated by browser
      $log.info(`Browser is translating to ${lang.value}; system language is ${translationStore.localeCode}`);
      if (toastForBrowserAutoTranslationWarning.value) {
        toastForBrowserAutoTranslationWarning.value.goAway();
      }
      toastForBrowserAutoTranslationWarning.value = toastsStore.showToast({ toastMessage, toastOptions });
    } else if (toastForBrowserAutoTranslationWarning.value) {
      // Translation turned off
      toastForBrowserAutoTranslationWarning.value.goAway();
    }
  });
  // pass in the element you want to watch, as well as the options
  if (targetChromeAndSafari) {
    observerChromeAndSafari.observe(targetChromeAndSafari, observerOptions);
  }
  // later, you can stop observing
  // observer.disconnect();

  // create an observer instance for Edge
  const observerEdge = new MutationObserver((mutationRecords) => {
    /**
      this is the callback where you do what you need to do.
     The argument is an array of MutationRecords where the affected attribute is named "attributeName".
     There is a few other properties in a record but I'll let you work it out yourself.
     */
    const mutationRecord = mutationRecords.find((mr) => mr.attributeName === edgeAttributeName);
    if (!mutationRecord || !mutationRecord.target) {
      // Not what we are looking for
      return;
    }
    // target will be <title>
    $log.debug(`Detected attribute ${edgeAttributeName} mutation at <title> element`);
    if ((mutationRecord.target as Element).attributes.getNamedItem(edgeAttributeName)) {
      // Translated by browser (Chrome adds this "translated-ltr" class to the html element)
      $log.info(`Browser is translating; system language is ${translationStore.localeCode}`);
      if (toastForBrowserAutoTranslationWarning.value) {
        toastForBrowserAutoTranslationWarning.value.goAway();
      }
      toastForBrowserAutoTranslationWarning.value = toastsStore.showToast({ toastMessage, toastOptions });
    } else if (toastForBrowserAutoTranslationWarning.value) {
      // Translation is now turned off
      toastForBrowserAutoTranslationWarning.value.goAway();
    } else if (toastForBrowserAutoTranslationWarningFirstTime.value && autoTranslationOnEdgeDetected.value) {
      toastForBrowserAutoTranslationWarningFirstTime.value.goAway();
      autoTranslationOnEdgeDetected.value = false;
    }
  });
  // pass in the element you want to watch, as well as the options
  if (targetEdge) {
    observerEdge.observe(targetEdge, observerOptions);
  }
  // later, you can stop observing
  // observerForEdge.disconnect();
  //
  //
  //
  // Check it the first time, in case the user selected "always translate" (will not trigger the change when the page loads "already" translated)
  //
  // Edge (check before Chrome/Safari, as Edge will also have the lang attribute at <html> but we can't identify that way):
  if (targetEdge
    && targetEdge.attributes.getNamedItem(edgeAttributeName)
    && !toastForBrowserAutoTranslationWarningFirstTime.value) {
    $log.info(`Browser is translating (Edge?) (detected at start, meaning it probably is set to "always translate"); system language is ${translationStore.localeCode}`);
    toastForBrowserAutoTranslationWarningFirstTime.value = toastsStore.showToast({ toastMessage, toastOptions });
    autoTranslationOnEdgeDetected.value = true;
    return;
  }
  const langAttribute = targetChromeAndSafari && targetChromeAndSafari.attributes.getNamedItem(chromeAndSafariAttributeName);
  // Chrome/Safari:
  if (targetChromeAndSafari
    && langAttribute
    && langAttribute.value
    && !toastForBrowserAutoTranslationWarningFirstTime.value) {
    if (targetChromeAndSafari.classList.contains('translated-ltr')) {
      // Chrome
      $log.info(`Browser is translating (Chrome?) (detected at start, meaning it probably is set to "always translate") to ${langAttribute.value}; system language is ${translationStore.localeCode}`);
      toastForBrowserAutoTranslationWarningFirstTime.value = toastsStore.showToast({ toastMessage, toastOptions });
      return;
    }
    // Safari (or Chrome without "translated-ltr" but with different "lang" from selected language):
    const simpleLang = langAttribute.value.split('-')[0].toLowerCase();
    const simpleSystemLang = translationStore.localeCode.split('-')[0].toLowerCase();
    if (lastLangDetected === simpleLang) {
      return; // already detected; stop the loop on Safari
    }
    lastLangDetected = simpleLang;
    if (simpleLang !== simpleSystemLang) {
      $log.info(`Browser is translating (Safari?) (detected at start, meaning it probably is set to "always translate") to ${langAttribute.value}; system language is ${translationStore.localeCode}`);
      toastForBrowserAutoTranslationWarningFirstTime.value = toastsStore.showToast({ toastMessage, toastOptions });
    }
  }
}

function openDebugModal() {
  if (globalStore.isProduction) {
    return;
  }
  isDebugModalVisible.value = true;
}

async function checkForNewVersion() {
  const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
  const nextRetryMs = getRandomInteger(40, 60) * 1000; // retry in 40 to 60 seconds

  try {
    type VersionResponse = AxiosResponse<{
      version: string;
      showChanges: boolean;
    }>;

    const response: undefined | VersionResponse = await $http?.get('/version', { baseURL: '/', signal: undefined });
    if (!response) {
      // No $http? Check again later
      $log.warn('checkForNewVersion(): no $http?');
      setTimeout(checkForNewVersion, nextRetryMs);
      return;
    }
    const { version, showChanges } = response.data;

    if (!globalStore.currentVersion) {
      // No current version at the store
      // Store will check if there is a version at the local storage, and will show "What’s new?" if different and showChanges is true)
      globalStore.setCurrentVersion({ version, showChanges });
      // Check again later
      setTimeout(checkForNewVersion, nextRetryMs);
    } else if (globalStore.currentVersion === version) {
      // Same version. Check again later
      setTimeout(checkForNewVersion, nextRetryMs);
    } else {
      // New version available
      globalStore.setCurrentVersion({ version, showChanges });
      // toastsStore.showToast({
      //   toastMessage: 'There is a new version available',
      //   toastOptions: {
      //     position: 'top-right',
      //     duration: null,
      //     type: 'info',
      //     action: {
      //       text: 'Refresh',
      //       onClick: () => {
      //         window.location.reload();
      //       },
      //     },
      //   },
      // });
    }
  } catch (error) {
    $log.info('checkForNewVersion(): error fetching:', error);
    // Deploying? Try again:
    setTimeout(checkForNewVersion, nextRetryMs);
  } finally {
    //
  }
}

async function getApiVersion() {
  $http?.get('/version', { signal: undefined }) // without baseURL will use the default /api/v1
    .then((responseApi) => {
      globalStore.setCurrentApiVersion({ version: responseApi.data.version });
    })
    .catch((error) => {
      globalStore.setCurrentApiVersion({ version: error.response && error.response.data ? JSON.stringify(error.response.data) : 'unknown' });
    });
}

// async function checkTest1() {
//   try {
//     const response = await $http?.get('/test1', { baseURL: '/', signal: undefined });
//     $log.info('checkTest1():', response.data);
//   } catch (error) {
//     $log.info('checkTest1(): error:', error);
//     // Try again:
//     setTimeout(checkTest1, 1000 * 5); // 5 in 5 seconds
//   } finally {
//     //
//   }
// }

// async function checkTest2() {
//   try {
//     const response = await $http?.get('/test2', { baseURL: '/', signal: undefined });
//     $log.info('checkTest2():', response.data);
//   } catch (error) {
//     $log.info('checkTest2(): error:', error);
//     // Try again:
//     setTimeout(checkTest2, 1000 * 120); // 120 in 120 seconds
//   } finally {
//     //
//   }
// }

// async function checkTest3() {
//   try {
//     const response = await $http?.get(`/test3?timestamp=${new Date().getTime()}`, { baseURL: '/', signal: undefined });
//     $log.info('checkTest3():', response.data);
//   } catch (error) {
//     $log.info('checkTest3(): error:', error);
//     // Try again:
//     setTimeout(checkTest3, 1000 * 30); // 30 in 30 seconds
//   } finally {
//     //
//   }
// }

function errorLoadingBackgroundImage() {
  $log.debug('errorLoadingBackgroundImage, will load user settings.');
  accountStore.fetchSettings(); // settings endpoint has the user background
}

function enterUserJourneyTalentManualDemoMode(phase: number, step: number) {
  let roleId;
  if (globalStore.isAppImp) {
    roleId = accountStore.allRoles.IMP_TEAM_LEADER.id;
  } else if (globalStore.isAppJgo) {
    roleId = accountStore.allRoles.JGO_PARTNER.id;
  } else if (globalStore.isAppTly || globalStore.isAppZae) {
    roleId = accountStore.allRoles.TLY_TEAM_LEADER.id;
  }
  accountStore.enterUserJourneyTalentManual({
    mode: 'demo',
    phase,
    step,
    userRoleId: roleId,
    returnPage: $route.name?.toString(),
  });
}

function enterUserJourneyV3DemoMode() {
  let roleId;
  if (globalStore.isAppImp) {
    roleId = accountStore.allRoles.IMP_TEAM_LEADER.id;
  } else if (globalStore.isAppJgo) {
    roleId = accountStore.allRoles.JGO_PARTNER.id;
  } else if (globalStore.isAppTly || globalStore.isAppZae) {
    roleId = accountStore.allRoles.TLY_TEAM_LEADER.id;
  }
  accountStore.enterUserJourneyV3({
    mode: 'demo',
    phase: 1, // phase,
    step: 0, // step,
    userRoleId: roleId,
    returnPage: $route.name?.toString(),
  });
}

function defineCustomVeeValidateRules() {
  defineRule('size-in-mb', (file: File, ruleValues: string[]) => {
    // To show MB instead of KB
    const fileSizeBytes = file.size;
    const maxSizeMegaBytes = Number.parseFloat(ruleValues[0]); // MB
    const maxSizeBytes = maxSizeMegaBytes * 1024 * 1024;
    if (fileSizeBytes > maxSizeBytes) {
      const fileSizeMegabytes = Math.round(((file.size / 1024 / 1024) + Number.EPSILON) * 100) / 100; // file.size is in bytes, convert to MB and round to two decimal places
      const fileSizeWithUnits = `${fileSizeMegabytes} MB`;
      const maxSizeWithUnits = `${maxSizeMegaBytes} MB`;
      return translationStore.t('customValidation.size', { fileSize: fileSizeWithUnits, maxSize: maxSizeWithUnits }).toString();
    }
    return true;
  });
}

getScrollWidthForThisBrowser();

onMounted(() => {
  // globalStore.appComponentMounted();
  listenForBrowserTranslationChanges();
  checkForNewVersion();
  getApiVersion();
  // checkTest1();
  // checkTest2();
  // checkTest3();
  defineCustomVeeValidateRules();
});
</script>

<style lang="scss">
//@import "/css/typekit-europa/index.css"; // main font
@use "../public/css/typekit-europa"; // main font
// For email previews:
@use "../public/css/istok-web"; // used in invitation email preview
// font used on coordinates display:
//@import "/css/ds-digital/index.css"; // old
//@import "/css/airport/index.css"; // old, previously used in DigitalDate.vue
@use "../public/css/zapfino"; // used in Personal Development certificate
@use "~animate.css" as animate; // https://animate.style/

@use "~bootstrap" as bootstrap;

:root {
  //
  // Font size: https://zellwk.com/blog/viewport-based-typography
  //
  font-size: 100%;

  /*@media (min-width: 600px) {*/
  @media #{$mq-small} { // 768px on 100%
    // 1) convert the smaller font-size (18px) into a percentage of the default 16px.
    // The first part of the calculation is thus: calc(18/16 * 100%) (or simply calc(112.5%)).
    $percentage: calc(18 / 16 * 100%);
    // 2) calculate the vw amount.
    // You calculate the vw value by taking the difference in font-size (20 - 18),
    // divide it by the difference in viewport widths (1800 - 600), then multiply it by 100vw - smaller-viewport-width (100vw - 600px).
    $vw-ammount: calc((20 - 18) / (1920 - 768) * (100vw - 768px));
    // Scales by 1px for every 100px from 768px onwards (
    font-size: calc(#{$percentage} + #{$vw-ammount});
  }

  /*@media (min-width: 1800px) {*/
  @media #{$mq-xlarge} { // 1920px on 100%
    // Sets font-size to 22px for a viewport >= 1920px
    //font-size: calc(137.5%);
    // Sets font-size to 20.8px for a viewport >= 1920px
    font-size: calc(130%);
  }
}

.full-background-like-preload,
.background-if-no-route-name {
  .theme-v3e & {
    background: var(--columns-background);
  }

  .app-jgo:not(.theme-v3e) & {
    background: #F4F1BE;
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

// Debug
.debug {
  z-index: 1029;

  .btn {
    font-size: $font-08px;
    background-color: #6897BB;
  }

  &.uj-make-token-valid,
  &.uj-skip-loading {
    top: 3.5rem;
    right: 12rem; // space for the theme selector
    z-index: 1;
  }

  &.invitation-page {
    top: 3.5rem;
    right: 12rem; // space for the theme selector
    z-index: 1;
  }

  &.debug-themes-and-roles {
    position: absolute;
    right: calc(14px + 1.135vw);
    top: 4vw;
  }
}

//.error-page-background-preload {
//  display: none;
//  position: absolute;
//  width: 0;
//  height: 0;
//  background: url('/img/backgrounds/IMP-Plane-Repair.jpg');
//}

#app {
  font-size: $font-16px; // 1rem

  //&.theme-v1 {
  //  --fire-color: #A01A1A;
  //  --air-color: #f6bf08;
  //  --water-color: #4FA096;
  //  --earth-color: #203362;
  //}

  --dark: #{$theme-v2-dark};

  --fire-color: #d40d12;
  --air-color: #fcc814;
  --water-color: #28b09c;
  --earth-color: #033980;

  &.theme-v3e {
    --dark: #{$theme-v3e-dark};

    --fire-color: #d9151d;
    --air-color: #fccf20;
    --water-color: #38baa8;
    --earth-color: #054a8f;

    font-size: unset;

    padding-top: 0;
    min-height: 100vh !important;
    background-color: $theme-v3e-background;

    // Other variables
    --impulse-area-elements-padding: calc(#{$gap-0_24x} * 1.38); // = 33%
    //--impulse-area-elements-padding: calc(#{$gap-1x} * 1.38);
  }

  --text-color-1: #{$theme-v3e-blue-1};
  --text-color-1_rbg: 78, 100, 137;
  --text-color-2: #{$theme-v3e-blue-2};
  --text-color-3: #{$theme-v3e-blue-3};
  --text-color-4: #{$theme-v3e-blue-4};
  --text-color-5: #{$theme-v3e-blue-5};
  --placeholder-color: var(--text-color-2);
  --bs-secondary-color: var(--text-color-2);
  //--bs-border-radius: ; // not used?

  --columns-background: #{$theme-v3e-columns-background};

  &.app-imp {
    --accent-color: #{$accent-imp};
    --accent-color_rgb: #{$accent-imp_rgb};
  }

  &.app-jgo {
    --accent-color: #{$accent-jgo};
    --accent-color_rgb: #{$accent-jgo_rgb};
  }

  &.app-jgo:not(.theme-v3e) {
    --accent-color: #{$accent-jgo-old};
    --accent-color_rgb: #{$accent-jgo_rgb-old};
    --text-color-1: rgba(0, 0, 0, .87); // override v3 with v2 color
    --text-color-2: rgba(0, 0, 0, .60); // override v3 with v2 color
    --text-color-3: rgba(0, 0, 0, .38); // override v3 with v2 color
    --columns-background: #{$theme-v2-columns-background}; // override v3 with v2 color
    --placeholder-color: rgba(255, 255, 255, .38);
  }

  &.app-tly {
    --accent-color: #{$accent-tly};
    --accent-color_rgb: #{$accent-tly_rgb};
  }

  &.app-zly {
    --text-color-1: #3A4A66;
    --accent-color: #{$accent-zly};
    --accent-color_rgb: #{$accent-zly_rgb};
  }

  &.app-zae {
    --accent-color: #{$accent-zae};
    --accent-color_rgb: #{$accent-zae_rgb};
  }

  /*min-height: 100vh;*/
  padding-top: $header-height; // in case we have a background image, to prevent it going behind the header; removed from v3?
  min-height: calc(100vh - #{$header-height});

  color: var(--text-color-1);
  font-family: 'europa', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.hide-header:not(.route-is-user-journey) {
    padding-top: 0;
    min-height: 100vh;

    .background-placeholder {
      height: 100vh;
    }

    .app-row {
      min-height: 100vh;
    }

    .router-view {
      top: 0;
    }
  }

  // Bootstrap vars
  --bs-body-color: var(--text-color-1); // #212529; // Default foreground (color) and background, including components.
  --bs-body-color-rgb: var(--text-color-1_rbg); // 33, 37, 41;
  --bs-body-bg: var(--columns-background); // #fff;
  --bs-body-bg-rgb: 241, 244, 249; // 255, 255, 255;
  --bs-emphasis-color: var(--text-color-1); // default: #000 // For higher contrast text. Not applicable for backgrounds.
  --bs-emphasis-color-rgb: var(--text-color-1_rbg);
}

.router-view {
  position: absolute;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: auto;

  // .theme-v3e & {
  //   //top: 6rem;
  // }

  &.show-scrollbar {
    overflow-y: scroll; // instead of "auto" to keep the scroll visible, avoiding "jumps" on the pages when showing/hiding
    $scrollbarWidth: var(--scrollbarWidth);

    .app-row {
      padding-right: calc(#{$gap-3x} - #{$scrollbarWidth}); // reduce the scroll size

      .theme-v3e & {
        padding-right: calc(#{$gap-2x} - #{$scrollbarWidth}); // reduce the scroll size
      }
    }
  }

  &.user-journey, &.sign-in-v3 {
    top: $header-height !important; // even if #app has hide-header
    &.hide-header {
      top: 0 !important;
    }
  }

  transition: opacity 1s ease-out, filter 1s ease-out;

  &.hide-to-show-background {
    //opacity: 0; // 2018-09-07
    filter: blur(4px); // 2021-11-04
    opacity: 0.4; // 2021-11-04
  }
}

.background-placeholder {
  // So that the background gradient doesn’t stretch all the way down when we have a scrollbar
  position: absolute;
  height: calc(100vh - #{$header-height});
  width: 100%;
  z-index: -10;

  &.is-image {
    background: var(--bgUrl) no-repeat center;
    background-size: cover;
  }

  &.route-is-user-journey {
    top: $header-height;
  }
}

.hotspot-to-show-background {
  position: absolute;
  $space-from-top-to-column-body: $header-height + $gap-1_62x + $gap-1_62x + $gap-0_38x;
  top: $space-from-top-to-column-body;
  right: $gap-0_62x;
  width: $gap-1_62x;
  height: calc(99vh - #{$space-from-top-to-column-body});
  z-index: 1; // above app-row
}

a {
  cursor: pointer;
  color: var(--accent-color);

  &:hover {
    color: rgba(var(--accent-color_rgb), 0.8);
  }

  &.disabled {
    cursor: default;
    color: $dark-disabled;
    pointer-events: none;

    &:hover {
      color: $dark-disabled;
    }
  }
}

.border-and-padding {
  border: 1px solid var(--dark);
  border-radius: $border-radius-6px;
  background-color: transparent;
  padding: 3px;

  &.light-border {
    border-color: var(--columns-background);
  }
}

@media (max-width: 768px) {
  .border-and-padding {
    padding: 2px;
  }
}

.border-6 {
  border-radius: $border-radius-6px;
}

.top-part-with-background {
  // Used in sign in page and first onboarding phase
  position: relative;
  display: flex;
  flex: 1;
}

.loading-bar {
  width: 100%;
  height: 0.366vw;
  background-color: $battleship-grey-three;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  .active-part {
    height: 0.366vw;
    background-color: var(--accent-color);
    transition: width 4s ease-out;
  }

  &.on-bottom-part {
    top: -0.366vw;
  }
}

.bottom-part-with-dark-background {
  // Used in sign in page and first onboarding phase
  position: relative;
  min-height: 20vh;
  display: flex;
  justify-content: space-between;
  @extend .dark-background-light-text;
  padding: $gap-1x $gap-2x;
}

.app-row {
  /*min-width: 1200px; // do now allow columns to shrink above this value*/
  /*max-width: 1920px; // do now allow columns to grow above this value*/
  // disabled for now, as it breaks login page
  margin-left: auto; // center
  margin-right: auto; // center
  min-height: calc(100vh - #{$header-height});

  flex: 1;
  display: flex;
  /*min-height: 100vh;*/
  justify-content: space-between;
  padding: $gap-between-header-and-columns $gap-3x 0 $gap-3x;

  .theme-v3e & {
    min-width: $bp-medium; // do now allow columns to shrink above this value*/
    max-width: $bp-large; // do now allow columns to grow above this value
    padding: $gap-between-header-and-columns $gap-2x $header-height $gap-2x;
  }

  .app-col {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: $font-size-default-text;

    // width % not taking into account the gap on the sides of the page
    // as 100% is the middle part without the side gaps

    &-3 {
      //$col-width: 30.9%;
      $col-width: 31.96%;
      flex: 0 0 $col-width;
      max-width: $col-width;

      .theme-v3e & {
        $col-width: 33.33%;
        flex-basis: $col-width;
        max-width: $col-width;
      }
    }

    &-2 {
      //$col-width: 63.6%;
      $col-width: 65.98%;
      flex: 0 0 $col-width;
      max-width: $col-width;

      .theme-v3e & {
        $col-width: 66.67%;
        flex-basis: $col-width;
        max-width: $col-width;
      }
    }

    &-1 {
      //$col-width: 96.3%;
      $col-width: 100%;
      flex: 0 0 $col-width;
      max-width: $col-width;
    }

    &:not(:last-child) {
      .app-col-body {
        .theme-v3e & {
          &:after {
            content: "";
            position: absolute;
            $width: 3px;
            width: $width;
            top: $gap-1_62x;
            right: calc(#{$width} * -.5);
            bottom: $gap-0_38x;
            border-radius: $width;
            z-index: 1;
          }
        }
      }
    }

    &:nth-child(1) {
      .app-col-header {
        .theme-v3e & {
          background-color: $theme-v3e-columns-header-background-1;
          color: var(--text-color-1);
        }
      }
    }

    &:nth-child(2) {
      .app-col-header {
        .theme-v3e & {
          background-color: $theme-v3e-columns-header-background-2;
          color: var(--text-color-1);
        }
      }
    }

    &:nth-child(3) {
      .app-col-header {
        .theme-v3e & {
          background-color: $theme-v3e-columns-header-background-3;
          color: var(--text-color-1);
        }
      }
    }
  }

  .app-col-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: $col-header-height;
    margin-bottom: $col-header-margin-bottom; // space between the header and the body
    background-color: var(--dark);
    border-radius: $border-radius-8px $border-radius-8px 0 0; // top only

    .theme-v3e & {
      margin: 0 $gap-1x $gap-1x $gap-1x;
      border-radius: $border-radius-6px;
      //height: calc(#{1.38 * $gap-1x});
      height: calc(#{1.19 * $gap-1x});
      @extend .neumorphic-bg-and-shadow-1;
      padding-bottom: 0.1rem; // move the text a little bit down
    }

    font-size: $font-size-column-header;

    font-weight: bold;
    color: $light-primary;

    cursor: default;

    &.highlighted-text {
      color: var(--accent-color) !important; // !important to override the !important from .neumorphic-bg-and-shadow-1
    }
  }

  .app-col-body {
    position: relative;
    flex: 1; // fixes height on Safari. height: 100% is not needed.
    background-color: var(--columns-background);
    color: var(--text-color-1);

    .app-col-body-content {
      min-height: calc(100vh - #{$header-height} - #{$gap-between-header-and-columns} - #{$col-header-height} - #{$col-header-margin-bottom});
      padding: $gap-1x;
      display: flex;
      flex-direction: column;

      .theme-v3e & {
        min-height: 0;
        padding: 0 $gap-1x;
      }

      &.large-image-on-column {
        padding: 0 !important;

        .theme-v3e & {
          padding: 0 $gap-1x !important; // same as other columns without large image
        }
      }
    }
  }
}

.p-25 {
  padding: 25px !important;
}

.p-20 {
  padding: $gap-1x !important;
}

//
//
//

b, strong {
  font-weight: bold;
}

.form-control {
  font-size: $font-12px; // 1rem is 16px on Chrome ?
}

// Override Bootstrap invalid color
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: $blush;
}

.form-control::placeholder {
  color: var(--placeholder-color);
}

::-webkit-input-placeholder { // WebKit browsers
  color: var(--placeholder-color);
}

:-moz-placeholder { // Mozilla Firefox 4 to 18
  color: var(--placeholder-color);
}

::-moz-placeholder { // Mozilla Firefox 19+
  color: var(--placeholder-color);
}

:-ms-input-placeholder { // Internet Explorer 10+
  color: var(--placeholder-color);
}

/* child components styles (that do not work inside the "scoped" styles above) */

.no-border-radius-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.invalid-feedback {
  color: color.adjust($blush, $lightness: -38%, $space: hsl);
  text-align: left;
  font-size: $font-12px;
  padding-left: 5px;

  .theme-v3e & {
    color: $theme-v3e-brown-1;
    font-weight: bold;
  }
}

.input-group-text {
  background-color: #fff;

  &.is-invalid {
    background-color: $form-invalid-input-group-background;
    border: 1px solid color.adjust($blush, $lightness: -38%, $space: hsl);

    svg {
      fill: color.adjust($blush, $lightness: -38%, $space: hsl) !important;
    }

    border-right: none;
  }
}

.btn {
  --bs-btn-border-radius: #{$border-radius-5px}; // 2023-04-24: the new Vue 3 BootstrapVueNext (using Bootstrap 5) defines "border-radius: var(--bs-btn-border-radius);" with 0.375rem instead of the old 0.25rem

  --dark-secondary: #48546b;
  //-bs-btn-hover-bg: #5c636a;
  //--bs-btn-hover-border-color: #565e64;
  --bs-btn-hover-bg: var(--dark-secondary);
  --bs-btn-hover-border-color: var(--dark-secondary);
  --bs-btn-active-bg: var(--dark-secondary);
  --bs-btn-active-border-color: var(--dark-secondary);

  &.btn-link {
    --bs-btn-border-width: 0; // 2023-04-24: .btn-link has a transparent border, but has 1px of width
  }
}

.btn-primary {
  font-weight: bold;
  background-color: $bg-btn-primary;
  box-shadow: 0 4px 10px 0 rgba(102, 204, 255, 0.5);
  border: none;

  &:hover:enabled {
    background-color: color.adjust($bg-btn-primary, $lightness: -8%, $space: hsl);
  }
}

// Add asterisk to required fields
fieldset.required {
  legend:after {
    content: "*";
  }

  &.no-required-star {
    legend:after {
      content: "";
    }
  }
}

.b-form-group.required {
  label:after {
    content: "*";
  }
}

//
// Tooltips (floating-vue)
//
.v-popper__popper {
  outline: none;
  border: none !important; // floating-vue default is 1px solid #3d4750
  display: block !important;
  z-index: 20000; // make it above the modals

  // .v-popper__wrapper {
  //   outline: none;

  //   .v-popper__inner {
  //     outline: none;
  //     border: none; // floating-vue default is 1px solid #ddd
  //   }
  // }

  --tooltip-color: var(--text-color-1);

  &.is-learning {
    --tooltip-color: #{$theme-v3e-brown-1};
  }

  .theme-v3e & {
    //color: white;

    a {
      color: white;
    }
  }

  &.custom-popover--personal-development-area--impulse-thumbnail {
    border: none !important;
  }

  .v-popper__inner {
    background: var(--tooltip-color);
    color: $light-primary;
    font-weight: bold;
    font-size: $font-10px;
    border-radius: $border-radius-3px;
    padding: 6px 10px 4px;
    min-height: 30px;
    max-width: 15vw; // default is 200px
  }

  &.p-62x {
    .v-popper__inner {
      padding: $gap-0_62x;
    }
  }

  &.custom-popover--personal-development-area--impulse-thumbnail {
    .v-popper__inner {
      width: 5rem;
      padding: 0;
      border-radius: $border-radius-6px;
      overflow: hidden; // fix image corners
      //background: red;
      color: $light-primary;
      font-weight: bold;
      font-size: $font-10px;
    }
  }

  &.medium {
    .v-popper__inner {
      max-width: 15vw;
    }
  }

  &.larger {
    .v-popper__inner {
      max-width: 25vw;
    }
  }

  &.align-left {
    .v-popper__inner {
      text-align: left;
    }
  }

  &.no-bold {
    .v-popper__inner {
      font-size: $font-12px;
      font-weight: normal;
    }
  }

  &.phase-and-step-tooltip {
    .v-popper__inner {
      text-align: left;
      max-width: unset;
    }
  }

  &.no-max-width {
    .v-popper__inner {
      max-width: unset;
    }
  }

  .v-popper__arrow-inner { // Vue 3
    border-color: var(--tooltip-color);
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.v-popper__popper {
    // ???
    /*$color: #f9f9f9;*/
    background-color: unset;
    /*border: 1px solid $light;*/
    border: 1px solid color.adjust($dark, $lightness: 10%, $space: hsl);
    max-width: 20vw;

    .v-popper__arrow-inner {
      border-color: var(--tooltip-color);
    }

    /* Custom */
    &.custom-popover-class,
    &.email-popover,
    &.file-info-popover {
      max-width: unset;

      .v-popper__inner {
        max-width: unset;
        padding: $gap-0_38x;
      }

      .small-app-button {
        .button {
          height: calc(#{$form-item-height} / 1.5); // subtract button border

          .label {
            font-size: $font-10px !important;
          }
        }
      }

      &.no-padding {
        .v-popper__inner {
          padding: 0;
        }
      }
    }

    &.file-info-popover {
      .v-popper__inner {
        text-align: left;
      }
    }

    &.nugget-highlight-popover {
      .v-popper__inner {
        text-align: left;
        padding: $gap-0_38x;
      }

      .v-popper__inner {
        max-width: unset;
      }

      .nugget-title {
        /*margin: $gap-0_38x 0;*/
        margin-bottom: calc(#{$gap-0_38x} / 2);
      }

      .nugget-description {
        font-weight: normal;
      }

      .action-open, .action-do-it-later {
        margin-top: $gap-0_38x;

        .button {
          height: calc(#{$form-item-height} / 1.5); // subtract button border

          .label {
            font-size: $font-10px !important;
          }
        }
      }
    }

    &.actions-popover {
      &:hover, &:focus {
        outline: none;
      }

      .v-popper__inner {
        padding: 0;

        &:focus {
          outline: none;
        }
      }
    }

    &.tooltip-for-circle-of-the-elements-button {
      max-width: 22rem;

      .v-popper__inner {
        padding: $gap-0_62x;
        max-width: unset;
      }
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

// FloatingVue (tooltips @ Vue 3)
.v-popper--theme-tooltip {
  // overrides the .v-popper__popper
  z-index: 20000; // make it above the modals (and above the navbar)

  --tooltip-color: var(--text-color-1);

  &.is-learning {
    --tooltip-color: #{$theme-v3e-brown-1};
  }

  .v-popper__inner {
    background: var(--tooltip-color);
    font-weight: bold;
    font-size: $font-10px;
    border-radius: $border-radius-3px;
  }

  .v-popper__arrow-container {
    pointer-events: none; // prevent tooltip from closing when cursor touches the arrow
  }

  .v-popper__arrow-outer {
    border-color: var(--tooltip-color);
  }
}

//
// Scrollbar
//
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E8E8E8;
  border: 4px solid transparent;
  background-clip: content-box; // this is important
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCFD2;
  /*border: 1px solid rgb(0, 0, 0);*/
  border-radius: $border-radius-8px;
}

.nav-item {
  &:focus {
    outline: none;
  }
}

.dropdown-menu {
  background-color: var(--dark);
  color: white;
  font-size: $font-12px;

  //--bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-radius: 0.25rem;

  %selected-item-style {
    background-color: var(--accent-color);
    color: $light-primary;
  }

  .dropdown-item, .dropdown-item-text {
    --bs-dropdown-item-padding-x: 1.5rem;
  }

  .dropdown-item-text {
    .app-jgo:not(.theme-v3e) & {
      color: $light-primary !important; // instead of --text-color-1, that would be dark on JGO
    }
  }

  .dropdown-item {
    color: $light-primary;
    font-weight: bold;

    &.text-secondary {
      color: $light-primary !important;
    }

    &:focus, &:hover {
      background-color: color.adjust($dark, $lightness: 10%, $space: hsl);

      .theme-v3e & {
        background-color: var(--text-color-4);
        color: $theme-v3e-dark !important;
      }
    }

    &:active,
    &.active,
    &.router-link-active {
      @extend %selected-item-style;
    }

    &.active-role {
      color: var(--accent-color) !important;
    }

    &.disabled {
      color: $light-disabled !important;

      $opacity: .50;

      &.active-role {
        color: rgba(var(--accent-color_rgb), $opacity) !important;
      }

      .theme-v3e & {
        //color: rgba($theme-v3e-text-color, $opacity) !important;
        color: var(--text-color-2) !important;
      }
    }
  }

  fieldset {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.filter-dropdown {
  button {
    background-color: var(--dark);
    line-height: $form-item-font-size;
    font-size: $form-item-font-size;
  }

  .dropdown-menu {
    .form-check {
      display: flex;
      align-items: center;
      padding-left: 2rem;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .form-check-input {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        margin-top: -3px;
      }

      .form-check-label {
        width: 100%; // inside dropdown, allows clicking after the text ends
        line-height: $form-item-font-size;
        font-size: $font-12px;
        cursor: pointer;
        margin-left: $gap-0_38x;
      }
    }

    // .filter-item {
    //   //padding: 0.3vw 1rem 0.3vw 1rem;
    // }
  }
}

.dropdown-toggle {
  width: 100%;
  padding-right: 1.5vw;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    // caret: align to the right
    position: absolute;
    right: 0.75rem; // same as the button left-padding
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-secondary {
  --bs-btn-disabled-bg: var(--text-color-2); // default is #6c757d;
  --bs-btn-disabled-border-color: var(--text-color-1); // default is #6c757d;

  &.dropdown-toggle.disabled {
    .theme-v3e & {
      // Match the disabled text inputs
      --bs-btn-disabled-bg: #{$theme-v3e-gray-1};
      color: rgba(255, 255, 255, 0.6);
      opacity: 1;
      box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  // clicked dropdown default style (Bootstrap):
  //color: #fff;
  //background-color: #545b62;
  //border-color: #4e555b;

  // custom style:
  color: var(--light);
  background-color: var(--dark);
  border-color: var(--dark);
  filter: brightness(138%); // make it brighter

  .app-imp &,
  .app-tly & {
    filter: unset;
  }
}

.container-with-screws {
  position: relative;
  padding: 45px;
  background: #fff;
  border-radius: $border-radius-6px;
}

.page-title {
  text-align: left;
  color: var(--text-color-1);
  margin: 30px 0;

  font-size: $font-23px;
  font-weight: bold;
  letter-spacing: 0.2px;
}

//
// Firefox workarounds
//
//select {
//  // Fix ugly select arrow
//  -webkit-appearance: none;
//  -moz-appearance: none;
//  appearance: none;
//  background: url('/img/dropdown.png') right / 20px no-repeat #fff !important;
//  padding-right: 30px;
//}

// remove dotted outline/border in Firefox
button:focus,
a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #495057;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

//
// Modals
//
.modal {
  --bs-modal-inner-border-radius: #{$border-radius-6px};

  .modal-dialog {
    width: 50vw;
    max-width: none;

    &.modal-sm {
      width: 30vw;
    }

    &.modal-lg {
      width: 62vw;
    }

    &.modal-xl {
      width: 72vw;
    }

    .modal-content {
      background-color: var(--columns-background);
    }

    .modal-header {
      --bs-modal-header-padding: 0.25rem 1rem; // default is 1rem 1rem
      @extend .dark-background-light-text;
      font-size: $font-20px;
      font-weight: bold;
      justify-content: center !important;

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    .modal-body {
      color: var(--text-color-1);
      text-align: left;
      padding: $gap-1x;
    }

    .modal-footer {
      border-top: none;
      padding: $gap-1x;
      justify-content: flex-start; // Bootstrap default is flex-end

      & > * {
        margin: 0; // Bootstrap default is 0.25rem
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%
  }

  &.org-chart-modal {
    .modal-dialog {
      width: 100vw;
      height: 100vh;
      margin: 0;

      .modal-content {
        height: 100%;
      }

      .modal-body {
        padding: 0 !important;
      }
    }
  }
}

.table {
  //--bs-table-color-type: initial;
  //--bs-table-bg-type: initial;
  //--bs-table-color-state: initial;
  //--bs-table-bg-state: initial;
  //--bs-table-color: var(--bs-body-color);
  //--bs-table-bg: var(--bs-body-bg);
  //--bs-table-border-color: var(--bs-border-color);
  //--bs-table-accent-bg: transparent;
  //--bs-table-striped-color: var(--bs-body-color);
  //--bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  //--bs-table-active-color: var(--bs-body-color);
  //--bs-table-active-bg: rgba(0, 0, 0, 0.1);
  //--bs-table-hover-color: var(--bs-body-color);
  //--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  //width: 100%;
  //margin-bottom: 1rem;
  //vertical-align: top;
  //border-color: var(--bs-table-border-color);

  color: var(--text-color-1);

  .theme-v3e & {
    color: var(--text-color-1);
  }

  &.dark-text {
    color: var(--text-color-1);

    &.table-hover tbody tr:hover {
      color: var(--text-color-1);
    }

    th, td, thead th {
      border-color: $dark-secondary;
    }
  }
}

.table-dark {
  background-color: var(--dark);
  color: $light-primary;

  th, td, thead th {
    border-color: $light-secondary;
  }
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.025);
}

.table-hover tbody tr:hover {
  .theme-v3e & {
    background-color: var(--text-color-4);
    color: var(--text-color-1); // keep the same color as when not hovered
  }
}

// Vue 3 tables (Bootstrap v5)
.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: unset; // keep the same text color on all rows
}

.VuePagination {
  .VuePagination__pagination-item {
    /*line-height: $form-item-font-size;*/

    a {
      @extend .dark-background-light-text;
      margin: 0 3px;
      padding: $gap-0_24x $gap-0_38x;
      border-radius: $border-radius-6px !important; // important to override bootstrap that removes border-radius on first and last items
      font-size: $form-item-font-size;
      line-height: $form-item-font-size;
      font-weight: bold;
      color: $light-primary;

      &:focus {
        /*box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);*/
        box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.18);
      }
    }

    &.active {
      a {
        color: $light-primary;
        border-color: #101010;
        background-image: radial-gradient(#2A2929, #000000);
        box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.18);
      }
    }

    &.disabled {
      a {
        background-color: var(--dark);
        border-color: #030404;
      }
    }
  }

  .VuePagination__count {
    display: none;
    color: var(--text-color-1);

    .theme-v3e & {
      color: unset;
    }
  }
}

//
// Inputs
//
fieldset {
  &.no-border {
    > div[role=group] {
      border: none !important;
      padding: 0 !important;
    }
  }

  &.over-dark-background {
    > div[role=group] {
      input, textarea {
        $color: color.adjust($dark, $lightness: 8%, $space: hsl);
        background-color: $color !important;
        border: 1px solid $color; // fixes the white corners at the sign in page

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px $color inset !important;
          caret-color: white; // fix the caret color
          border-radius: $border-radius-3px;
        }

        &:focus {
          border-color: $light-secondary;
        }
      }
    }
  }

  &.neumorphic-light {
    > div[role=group] {
      input, textarea {
        @extend .neumorphic-bg-and-shadow-1-selected;
        border-color: transparent;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          $text-color: var(--text-color-1);
          color: $text-color !important;
          -webkit-text-fill-color: $text-color !important;
          caret-color: $text-color; // fix the caret color

          $shadow-color-dark: rgba(#C8D2E6, 0.5);
          $shadow-color-light: rgba(255, 255, 255, 0.8);
          //-webkit-box-shadow: 0 0 0 30px white inset !important; // add this shadow as the last parameter of our shadow, to fix the autofill background color!
          box-shadow: inset 0.2rem 0.2rem 0.2rem $shadow-color-dark, inset -0.2rem -0.2rem 0.15rem $shadow-color-light, inset 0 0 0 30px $theme-v3e-columns-background !important;
        }

        &:focus {
          border-color: var(--text-color-2);
        }
      }
    }
  }

  &.is-invalid {
    div[role=group] {
      input, textarea, select, .dropdown button {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.38);
      }
    }
  }
}

.b-dropdown {
  button {
    height: $form-item-height;
  }
}

legend, label {
  font-size: $font-12px;
  font-weight: bold;
  line-height: 1.8;
  text-align: left;
  //color: var(--text-color-1); // not needed? use the color from the dropdown class?
  cursor: default;

  &.col-form-label {
    font-size: $font-12px; // Vue 3: needed, or else Bootstrap(?) will set it to inherit
    line-height: 1.8; // Vue 3: needed, or else Bootstrap(?) will set it to 1.5
    padding-top: 0;
    padding-bottom: 0;
    color: var(--text-color-1);
  }

  &.accent-color {
    color: var(--accent-color);
  }
}

legend.col-form-label,
label { // :has(+ input) {
  padding-left: 0.15rem; // align with the end of the input corner/border radius
}

.form-label {
  margin-bottom: 0; // 2024-11: because BootstrapVueNext started using <label> instead of <legend>, that adds a margin-bottom of 0.5rem on .form-label
}

.file-chooser {
  .b-form-file {
    /*.was-validated .custom-file-input:invalid ~ .custom-file-label,*/
    /*.custom-file-input.is-invalid ~ .custom-file-label {*/
    /*  !*border-color: $blush;*!*/
    /*  border-color: #6c757d;*/
    /*}*/

    /*.was-validated .custom-file-input:valid:focus ~ .custom-file-label,*/
    /*.custom-file-input.is-valid:focus ~ .custom-file-label {*/
    /*  !*box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);*!*/
    /*  box-shadow: none;*/
    /*}*/

    /*.was-validated .custom-file-input:valid ~ .custom-file-label,*/
    /*.custom-file-input.is-valid ~ .custom-file-label {*/
    /*  !*border-color: $lightblue;*!*/
    /*  border-color: #6c757d;*/
    /*}*/

    /*label {
        display: flex;
        align-items: center;
        height: 100%;

        background-color: $dark;

        font-size: $font-12px;
        font-weight: normal;
        line-height: unset;

        &::after {
          // Browse button
          display: flex;
          align-items: center;
          height: 100%;
        }
      }*/

    .custom-file-input[disabled] ~ .custom-file-label,
    .custom-file-input:disabled ~ .custom-file-label {
      /*background-color: #e9ecef;*/
      background-color: color.adjust($dark, $lightness: 8%, $space: hsl);
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse";
    }

    .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse);
    }

    .custom-file-input:focus ~ .custom-file-label {
      //border-color: $light-secondary;
      box-shadow: none;
    }

    .custom-file-label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      /*height: calc(1.5em + 0.75rem + 2px);*/
      height: $form-item-height;
      padding: 0.375rem 0.75rem;
      font-weight: 400;
      /*line-height: 1.5;*/
      /*line-height: $form-item-font-size;*/
      line-height: 1.2;
      /*color: #495057;*/
      color: $light-primary;
      /*background-color: #fff;*/
      background-color: var(--dark); // the file picker input, but not the "Browse" button
      box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.5);
      /*border: 1px solid #ced4da;*/
      border: 1px solid #272d33;
      border-radius: 0.25rem;
      font-size: $form-item-font-size;
    }

    .custom-file-label::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      /*height: calc(1.5em + 0.75rem);*/
      height: calc(#{$form-item-height} - 2px); // subtract the border
      padding: 0.375rem 0.75rem;
      /*line-height: 1.5;*/
      /*line-height: $form-item-font-size;*/
      line-height: 1.2;
      /*color: #495057;*/
      color: $light-primary;
      content: "Browse";
      /*background-color: #e9ecef;*/
      background-color: var(--dark); // the file picker input "Browse" button
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}

/*}*/
// .login-page {
//   [role=group] {
//     /*width: 15vw;*/
//     /*min-width: 100px;*/
//     /*border: 1px solid $battleship-grey-three;*/
//   }
// }

.password-reset-page {
  [role=group] {
    min-width: 25vw;
    /*border: 1px solid $battleship-grey-three;*/

    .theme-v3e & {
      min-width: unset;
    }
  }
}

.radio {
  [role=group] {
    border: none;
  }
}

// .range [role=group] {
//   /*border: none;*/
//
//   input {
//     /*border: 1px solid var(--dark);*/
//   }
// }

/*.col-form-label {*/
/*font-size: $font-14px;*/
/*}*/

input:not([data-plyr]):not([type=checkbox]):not([type=radio]):not([type=file]), textarea, select,
select.form-select,
textarea.form-control,
input.form-control, select.form-control, select.custom-select {
  //border: none;
  height: $form-item-height;
  border: 1px solid var(--dark);
  border-radius: $border-radius-5px;
  background-color: var(--dark);
  box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.5);
  color: $light-primary;
  font-size: $form-item-font-size;
  padding: 0 calc(#{$form-item-height} * .38);

  &.dp__input_icon_pad {
    // date picker padding for the left icon
    padding-left: var(--dp-input-icon-padding);
  }

  &:disabled {
    background-color: $theme-v3e-gray-1;
  }

  &:disabled:not(:read-only) {
    background-color: var(--dark); // ? (check :read-only)
    opacity: 0.62;
  }

  &:not([size]):not([multiple]) {
    height: $form-item-height; // override Bootstrap
  }

  &:focus {
    color: $light-primary;
    border-color: $light-secondary;
    box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--dark); // to override the Bootstrap .form-control:focus { background-color: var(--bs-body-bg); }
  }

  &.login-page, &.password-reset-page {
    height: $form-item-height;
    border: 1px solid $battleship-grey-three;
  }
}

select.form-select {
  // BFormSelect: apply the same style as the BDropdown
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  //--bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpolygon fill='%23ffffff' points='4 4, 14 4, 9 10'/%3e%3c/svg%3e");
  background-position: right 0.62rem center;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      --dark-secondary: #48546b;
      --bs-btn-hover-bg: var(--dark-secondary);
      --bs-btn-hover-border-color: var(--dark-secondary);
      background-color: var(--bs-btn-hover-bg);
      border-color: var(--bs-btn-hover-border-color);

      option {
        // keep the default color, not the hover one
        background: var(--dark);
      }
    }
  }
}

input:not([data-plyr]):not([type=checkbox]):not([type=radio]):not([type=file]) {
  &:-moz-read-only { /* For Firefox */
    color: $light-secondary;
    background-color: color.adjust($dark, $lightness: 8%, $space: hsl) !important;
    cursor: default;

    &:focus {
      border-color: var(--dark);
    }
  }

  &:read-only {
    color: $light-secondary;
    background-color: color.adjust($dark, $lightness: 8%, $space: hsl) !important;
    cursor: default;

    .theme-v3e & {
      background-color: $theme-v3e-gray-1 !important; // !important needed on select
    }

    &:focus {
      border-color: var(--dark);
    }

    &.slider {
      background-color: var(--dark) !important;
    }
  }
}

textarea,
textarea.form-control {
  height: auto !important;
  padding: 0.37rem 0.74rem; // was padding: 0.4vw 0.8vw;
}

//
//
// Vue Tour
//
//
.v-step {
  z-index: 1000;
  background-color: var(--dark);
  border: solid 3px $battleship-grey-three;
  color: $light-primary;
  max-width: 320px;
  border-radius: $border-radius-6px;
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, .5));
  padding: 1rem;
  text-align: left;
  font-size: $font-15px;
}

.v-step .v-step__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: .5rem;
  border-color: var(--dark);
}

.v-step .v-step__arrow--dark {
  border-color: var(--dark);
}

.v-step[x-placement^=top] {
  margin-bottom: .5rem;
}

.v-step[x-placement^=top] .v-step__arrow {
  border-width: .5rem .5rem 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -.5rem;
  left: calc(50% - 1rem);
  margin-top: 0;
  margin-bottom: 0;
}

.v-step[x-placement^=bottom] {
  margin-top: .5rem;
}

.v-step[x-placement^=bottom] .v-step__arrow {
  border-width: 0 .5rem .5rem;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -.5rem;
  left: calc(50% - 1rem);
  margin-top: 0;
  margin-bottom: 0;
}

.v-step[x-placement^=right] {
  margin-left: .5rem;
}

.v-step[x-placement^=right] .v-step__arrow {
  border-width: .5rem .5rem .5rem 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -.5rem;
  top: calc(50% - 1rem);
  margin-left: 0;
  margin-right: 0;
}

.v-step[x-placement^=left] {
  margin-right: .5rem;
}

.v-step[x-placement^=left] .v-step__arrow {
  border-width: .5rem 0 .5rem .5rem;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -.5rem;
  top: calc(50% - 1rem);
  margin-left: 0;
  margin-right: 0;
}

.v-step__header {
  margin: -1rem -1rem .5rem;
  padding: .5rem;
  background-color: #454d5d;
  @extend .top-corners-3;
}

.v-step__content {
  margin: 0 0 1rem;
  @extend .allow-new-line;
}

.v-step__button {
  background: transparent;
  border: .05rem solid #fff;
  border-radius: .1rem;
  color: $light-primary;
  cursor: pointer;
  display: inline-block;
  font-size: .8rem;
  height: $form-item-height;
  line-height: 1rem;
  outline: none;
  padding: .35rem .4rem;
  text-align: center;
  text-decoration: none;
  transition: all .2s ease-out;
  vertical-align: middle;
  white-space: nowrap;
}

.v-step__button:hover {
  background-color: hsla(0, 0%, 100%, .95);
  color: #50596c;
}

.v-step__buttons {
  display: flex;
  align-items: center;
}

.v-step__custom-link {
  font-size: $font-10px;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.v-step__custom-button {
  /*width: fit-content;*/
  .button {
    height: calc(#{$form-item-height} * 2 / 3) !important;
  }

  .label {
    font-size: 13px !important;
  }
}

//
//
// end of Vue Tour
//
//

.default-title {
  font-size: $font-size-default-title;
  //line-height: $line-height-default-title;
  text-align: left;
  font-weight: bold;
  margin-bottom: $gap-0_62x;

  &.highlight {
    color: var(--accent-color);
  }
}

.default-title-smaller-no-mb {
  font-size: $font-16px; // the default-title font is too big
  font-weight: bold;

  &.highlight {
    color: var(--accent-color);
  }
}

.default-text {
  font-size: $font-size-default-text;
  line-height: $line-height-default-text;
  text-align: left;

  //p {
  //  margin-bottom: 2vh;
  //
  //  &:last-child {
  //    margin-bottom: 0;
  //  }
  //}
}

.fit-content {
  width: fit-content;
}

.accordions {
  border-radius: $border-radius-8px;
}

.accordion {
  //--bs-accordion-color: #212529;
  //--bs-accordion-bg: #fff;
  //--bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  //--bs-accordion-border-color: var(--bs-border-color);
  //--bs-accordion-border-width: 1px;
  //--bs-accordion-border-radius: 0.375rem;
  //--bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  //--bs-accordion-btn-padding-x: 1.25rem;
  //--bs-accordion-btn-padding-y: 1rem;
  //--bs-accordion-btn-color: #212529;
  //--bs-accordion-btn-bg: var(--bs-accordion-bg);
  //--bs-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#212529'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  //--bs-accordion-btn-icon-width: 1.25rem;
  //--bs-accordion-btn-icon-transform: rotate(-180deg);
  //--bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  //--bs-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#0c63e4'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  //--bs-accordion-btn-focus-border-color: #86b7fe;
  //--bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  //--bs-accordion-body-padding-x: 1.25rem;
  //--bs-accordion-body-padding-y: 1rem;
  //--bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: unset; // #e7f1ff;

  &:not(:first-child):not(.hidden) {
    //.accordion-button {
    margin-top: $gap-0_38x;

    .theme-v3e & {
      margin-top: $gap-0_62x;
    }

    //}
  }

  .accordion-content {
    @extend .dark-background-light-text;
    padding: $gap-0_62x;
    margin-top: $gap-0_38x;
    text-align: left;
    border-radius: $border-radius-6px;

    &.inner {
      padding: 0 0 0 $gap-0_62x; // left only

      .theme-v3e & {
        padding: $gap-0_62x; // all sides
      }
    }

    .description {
      font-size: $font-size-default-text;
      @extend .allow-new-line;

      &:not(:first-child) {
        margin-top: $gap-0_38x; // when there are child buttons above
      }

      &.white-background {
        .theme-v3e & {
          margin: calc(#{$gap-0_62x} * -1 + (0.375rem * 1.38)); // revert .accordion-content padding and add our own
          padding: $gap-1x;
          background-color: white;
          border-radius: $border-radius-6px;
        }
      }

      .theme-v3e & {
        color: var(--text-color-1);
      }
    }

    .theme-v3e & {
      @extend .neumorphic-bg-and-shadow-1-selected;
    }
  }

  .accordion-child-button {
    &:not(:first-child) {
      margin-top: $gap-0_38x;
    }
  }
}

.accordion-button::after {
  // 2023-04-24: remove the arrow from the new Vue 3 BootstrapVueNext (using Bootstrap 5)
  display: none;
}

//
// Radio buttons & Checkboxes
//
.form-check {
  font-size: 1rem;
}

.form-check-input {
  &.dark-on-theme-v3:not(:checked) {
    border: 1px solid var(--dark);
    background-color: var(--dark);
  }

  &:focus {
    border-color: var(--accent-color); // #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--accent-color_rgb), 0.25); // rgba(13, 110, 253, 0.25);
  }

  &[type=radio] {
    border: unset;
    @extend .neumorphic-bg-and-shadow-1-sharper;

    &:not(:checked) {
      .app-jgo:not(.theme-v3e) & {
        box-shadow: none;
        background: #fff;
      }
    }

    &:checked {
      @extend .neumorphic-bg-and-shadow-1-selected-sharper;

      .app-jgo:not(.theme-v3e) & {
        box-shadow: none;
        background: #41B883;
      }

      // The inner circle

      @function getCircleWithColor($color) {
        @return bootstrap.escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='1.5' fill='#{$color}'/></svg>"));
      }

      .app-imp & {
        background-image: #{getCircleWithColor($accent-imp)};
      }

      .app-jgo & {
        background-image: #{getCircleWithColor($accent-jgo)};

        &:not(.theme-v3e) {
          background-image: #{getCircleWithColor('#fff')};
        }
      }

      .app-tly & {
        background-image: #{getCircleWithColor($accent-tly)};
      }

      .app-zly & {
        background-image: #{getCircleWithColor($accent-zly)};
      }

      ~ .form-check-label {
        color: var(--accent-color);
      }
    }
  }

  &[type=checkbox] {
    &:not(:checked) {
      .app-jgo:not(.theme-v3e) & {
        box-shadow: none;
        background-color: #fff;
      }
    }

    &:checked {
      background-color: var(--accent-color);
      border-color: var(--accent-color);

      ~ .form-check-label {
        color: var(--accent-color);
      }
    }
  }
}

//
// Transitions
//
.fade-enter-active, .fade-leave-active,
.fade-100-enter-active, .fade-100-leave-active,
.fade-200-enter-active, .fade-200-leave-active,
.fade-300-enter-active, .fade-300-leave-active,
.fade-500-enter-active, .fade-500-leave-active,
.fade-1000-enter-active, .fade-1000-leave-active,
.fade-1500-enter-active, .fade-1500-leave-active {
  transition-property: opacity;
}

.fade-enter-from, .fade-leave-to,
.fade-100-enter-from, .fade-100-leave-to,
.fade-200-enter-from, .fade-200-leave-to,
.fade-300-enter-from, .fade-300-leave-to,
.fade-500-enter-from, .fade-500-leave-to,
.fade-1000-enter-from, .fade-1000-leave-to,
.fade-1500-enter-from, .fade-1500-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition-duration: 1s;
}

.fade-100-enter-active, .fade-100-leave-active {
  transition-duration: .1s;
}

.fade-200-enter-active, .fade-200-leave-active {
  transition-duration: .2s;
}

.fade-300-enter-active, .fade-300-leave-active {
  transition-duration: .3s;
}

.fade-500-enter-active, .fade-500-leave-active {
  transition-duration: .5s;
}

.fade-1000-enter-active, .fade-1000-leave-active {
  transition-duration: 1s;
}

.fade-1500-enter-active, .fade-1500-leave-active {
  transition-duration: 1.5s;
}

//
// Video player
//
.plyr__poster {
  background-color: var(--columns-background);
  background-size: cover;
}

.plyr__control--overlaid {
  background-color: var(--accent-color);
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover,
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: var(--accent-color);
}

.plyr--full-ui input[type=range] {
  color: var(--accent-color);
}

/*.plyr--fullscreen-active video {*/
/*max-height: 50% !important*/
/*}*/

// Rubber stamp
.rubber-stamp {
  filter: alpha(opacity=40); /* For IE8 and earlier */
  /*font-family: 'Vollkorn', serif;*/
  font-size: $font-25px;
  line-height: 1.5vw;
  text-transform: uppercase;
  font-weight: bold;
  float: left;
  padding: 0.8vw 0.7vw;
  margin-bottom: $gap-2x;
  border-radius: 10px;

  opacity: 0.8;
  transform: rotate(-5deg);
  position: relative;
  top: 40px;
  /*top: 32%;*/

  /*user-select: none; // prevent text selection*/
  pointer-events: none; // allows click-through?

  color: var(--accent-color);
  border: 0.3vw solid var(--accent-color);

  &::after {
    position: absolute;
    content: " ";
    width: 110%;
    height: 150%;
    min-height: 100%;
    top: -10px;
    left: -10px;
    padding: 10px;
  }

  &.no-holes {
    &::after {
      background: none;
    }
  }

  &.red {
    color: $blush;
    border: 5px solid $blush;
  }

  &.accuracy {
    font-size: $font-20px;
    line-height: 1.59;
    font-weight: bold;
    padding-bottom: 15px;
  }

  &.not-floating {
    float: none;
    width: fit-content;
  }
}

.nav-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto calc(#{$gap-1x} / -2) 0 calc(#{$gap-1x} / -2);
  padding-top: $gap-1x;
}

.nav-buttons, .form-action-buttons {
  .nav-button-wrapper {
    max-width: 100%;
    padding: 0 calc(#{$gap-1x} / 2);
    flex: 1;

    &.half {
      margin-right: $gap-1x;
      flex: 0.5;
    }

    &:first-child {
      margin-bottom: $gap-0_62x;
    }

    &:last-child {
      margin-bottom: $gap-1x;
    }
  }
}

//
//
//
.switch-wrapper {
  margin: 0 6px;
  cursor: pointer;
  padding: 3px;
  border-radius: $border-radius-3px;
  background-color: var(--dark); // inside the border

  /*$switch-width: 74px;*/
  /*$switch-height: 26px;*/
  $switch-width: 5.4vw;
  $switch-height: calc(2vw - 6px); // subtract the 3px padding

  .switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: $switch-width;
    height: $switch-height;
    border-radius: $border-radius-3px;
    background-color: var(--dark);
    font-size: $font-12px;
    font-weight: bold;
    color: $light-primary;

    .switch-button {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      /*width: 37px;*/
      width: calc(#{$switch-width} / 2);
      height: $switch-height;
      background-image: linear-gradient(to bottom, #51585d, #44494e);
      /*padding: 6px 6px 10px 4px;*/
      padding: 0.4vh 0 0.8vh 0;
      border-radius: $border-radius-3px;

      .svg-wrapper {
        position: relative;
        width: 40%;
        height: 100%;
        margin-right: 2px; // compensate button border
        /*outline: 1px solid red;*/
      }

      &:not(.with-font-awesome-icon) {
        svg {
          overflow: visible; // fix SVG being cut by 1px at the top/side on Firefox
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .background {
            fill: #4A4F54;
          }

          .color {
            fill: #6E777F;
          }

          .stroke {
            stroke: #6E777F;
          }
        }
      }

      &.on {
        left: unset;
        right: 0;

        &:not(.with-font-awesome-icon) {
          svg {
            .color {
              fill: $light-primary;
            }

            .stroke {
              stroke: $light-primary;
            }
          }
        }
      }

    }
  }
}

//
// Toasted
//
.toasted-container {
  z-index: 15000; // above the modals

  &.top-right, &.bottom-right {
    right: calc(#{$gap-3x} / 2); // what about the scroll at the side of the page?
  }

  &.top-right {
    top: $header-height;
  }

  i {
    margin-right: $gap-0_38x;
  }
}

.toasted .primary,
.toasted.toasted-primary {
  border-radius: $border-radius-6px !important;

  /*.toasted .primary,
    .toasted.toasted-primary {
      border-radius: $border-radius-6px !important;
    }*/

  .action {
    padding: 15px;
    color: $lightblue;

    &:hover {
      text-decoration: none !important; // remove underline (use !important because toasted CSS overrides this)
    }

    &.close-toast {
      margin-left: $gap-0_38x;
      font-size: $font-16px;
    }
  }

  .custom-notification-title {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 16px;
  }

  &.info {
    /*background-color: #4caf50;*/
    background-color: #bbdefb !important; // Blue 100 (use !important because toasted CSS overrides this)
    border-left: 0.2vw solid #1e88e5; // Blue 600
    color: #0d47a1; // Blue 900

    i {
      color: #1e88e5;
    }
  }

  &.success {
    /*background-color: #4caf50;*/
    background-color: #c8e6c9 !important; // Green 100 (use !important because toasted CSS overrides this)
    border-left: 0.2vw solid #43a047; // Green 600
    color: #1b5e20; // Green 900

    i {
      color: #43a047;
    }
  }

  &.error {
    /*background: #F44336;*/
    /*background: $blush !important;*/
    background-color: #ffcdd2 !important; // Red 100 (use !important because toasted CSS overrides this)
    border-left: 0.2vw solid #e53935; // Red 600
    color: #b71c1c; // Red 900

    i {
      color: #e53935;
    }
  }

  &.dark-toast {
    background: var(--dark) !important;
    color: $light-primary !important;
    border-left: none !important;
  }

  &.toast-urgent {
    // same as error style? refactor.
    background-color: #ffcdd2 !important; // Red 100 (use !important because toasted CSS overrides this)
    border-left: 0.2vw solid #e53935; // Red 600
    color: #b71c1c; // Red 900

    i {
      color: #e53935;
    }
  }
}

//
// Spinner
//
.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-container-inline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.environment-and-version {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(0, 50%);
  background-color: rgba($theme-v3e-dark, 0.3);
  padding: $gap-0_24x $gap-0_62x;
  color: white;
  font-size: $font-10px;
  font-weight: bold;
  white-space: pre;
}

.debug-page-button {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  $size: 10px;
  width: $size;
  height: $size;
  background-color: rgba($theme-v3e-dark, 0.3);
  cursor: pointer;

  &.transparent {
    background-color: transparent;
  }
}

.debug-toggle-force-hide-debug-buttons {
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 15px;
  //width: 15px;
  height: 10px;
  cursor: pointer;

  // eye
  font-size: 12px;
  line-height: 10px;
  color: var(--text-color-1);

  &.transparent:not(:hover) {
    background-color: transparent;
    color: transparent;
  }
}

.allow-new-line {
  white-space: pre-line; // allow \n
}

//
// Margins
//
.m {
  &-gap-0_15x {
    margin: $gap-0_15x !important;
  }

  &-gap-0_24x {
    margin: $gap-0_24x !important;
  }

  &-gap-0_38x {
    margin: $gap-0_38x !important;
  }

  &-gap-0_62x {
    margin: $gap-0_62x !important;
  }

  &-gap-1x {
    margin: $gap-1x !important;
  }

  &-gap-1_62x {
    margin: $gap-1_62x !important;
  }

  &-gap-2x {
    margin: $gap-2x !important;
  }

  &-gap-3x {
    margin: $gap-3x !important;
  }
}

.mt {
  &-gap-0_15x {
    margin-top: $gap-0_15x !important;
  }

  &-gap-0_24x {
    margin-top: $gap-0_24x !important;
  }

  &-gap-0_38x {
    margin-top: $gap-0_38x !important;
  }

  &-gap-0_62x {
    margin-top: $gap-0_62x !important;
  }

  &-gap-1_62x {
    margin-top: $gap-1_62x !important;
  }

  &-gap-1x {
    margin-top: $gap-1x !important;
  }

  &-gap-2x {
    margin-top: $gap-2x !important;
  }

  &-gap-3x {
    margin-top: $gap-3x !important;
  }
}

.mr {
  &-gap-0_15x {
    margin-right: $gap-0_15x !important;
  }

  &-gap-0_24x {
    margin-right: $gap-0_24x !important;
  }

  &-gap-0_38x {
    margin-right: $gap-0_38x !important;
  }

  &-gap-0_62x {
    margin-right: $gap-0_62x !important;
  }

  &-gap-1_62x {
    margin-right: $gap-1_62x !important;
  }

  &-gap-1x {
    margin-right: $gap-1x !important;
  }

  &-gap-2x {
    margin-right: $gap-2x !important;
  }

  &-gap-3x {
    margin-right: $gap-3x !important;
  }
}

.mb {
  &-gap-0_15x {
    margin-bottom: $gap-0_15x !important;
  }

  &-gap-0_24x {
    margin-bottom: $gap-0_24x !important;
  }

  &-gap-0_38x {
    margin-bottom: $gap-0_38x !important;
  }

  &-gap-0_62x {
    margin-bottom: $gap-0_62x !important;
  }

  &-gap-1x {
    margin-bottom: $gap-1x !important;
  }

  &-gap-1_62x {
    margin-bottom: $gap-1_62x !important;
  }

  &-gap-2x {
    margin-bottom: $gap-2x !important;
  }

  &-gap-3x {
    margin-bottom: $gap-3x !important;
  }
}

.ml {
  &-gap-0_15x {
    margin-left: $gap-0_15x !important;
  }

  &-gap-0_24x {
    margin-left: $gap-0_24x !important;
  }

  &-gap-0_38x {
    margin-left: $gap-0_38x !important;
  }

  &-gap-0_62x {
    margin-left: $gap-0_62x !important;
  }

  &-gap-1_62x {
    margin-left: $gap-1_62x !important;
  }

  &-gap-1x {
    margin-left: $gap-1x !important;
  }

  &-gap-2x {
    margin-left: $gap-2x !important;
  }

  &-gap-3x {
    margin-left: $gap-3x !important;
  }
}

//
// Paddings
//
.p {
  &-gap-0_15x {
    padding: $gap-0_15x !important;
  }

  &-gap-0_24x {
    padding: $gap-0_24x !important;
  }

  &-gap-0_38x {
    padding: $gap-0_38x !important;
  }

  &-gap-0_62x {
    padding: $gap-0_62x !important;
  }

  &-gap-1x {
    padding: $gap-1x !important;
  }

  &-gap-1_62x {
    padding: $gap-1_62x !important;
  }

  &-gap-2x {
    padding: $gap-2x !important;
  }

  &-gap-3x {
    padding: $gap-3x !important;
  }
}

.pt {
  &-gap-0_15x {
    padding-top: $gap-0_15x !important;
  }

  &-gap-0_24x {
    padding-top: $gap-0_24x !important;
  }

  &-gap-0_38x {
    padding-top: $gap-0_38x !important;
  }

  &-gap-0_62x {
    padding-top: $gap-0_62x !important;
  }

  &-gap-1_62x {
    padding-top: $gap-1_62x !important;
  }

  &-gap-1x {
    padding-top: $gap-1x !important;
  }

  &-gap-2x {
    padding-top: $gap-2x !important;
  }

  &-gap-3x {
    padding-top: $gap-3x !important;
  }
}

.pr {
  &-gap-0_15x {
    padding-right: $gap-0_15x !important;
  }

  &-gap-0_24x {
    padding-right: $gap-0_24x !important;
  }

  &-gap-0_38x {
    padding-right: $gap-0_38x !important;
  }

  &-gap-0_62x {
    padding-right: $gap-0_62x !important;
  }

  &-gap-1_62x {
    padding-right: $gap-1_62x !important;
  }

  &-gap-1x {
    padding-right: $gap-1x !important;
  }

  &-gap-2x {
    padding-right: $gap-2x !important;
  }

  &-gap-3x {
    padding-right: $gap-3x !important;
  }
}

.pb {
  &-gap-0_15x {
    padding-bottom: $gap-0_15x !important;
  }

  &-gap-0_24x {
    padding-bottom: $gap-0_24x !important;
  }

  &-gap-0_38x {
    padding-bottom: $gap-0_38x !important;
  }

  &-gap-0_62x {
    padding-bottom: $gap-0_62x !important;
  }

  &-gap-1x {
    padding-bottom: $gap-1x !important;
  }

  &-gap-1_62x {
    padding-bottom: $gap-1_62x !important;
  }

  &-gap-2x {
    padding-bottom: $gap-2x !important;
  }

  &-gap-3x {
    padding-bottom: $gap-3x !important;
  }
}

.pl {
  &-gap-0_15x {
    padding-left: $gap-0_15x !important;
  }

  &-gap-0_24x {
    padding-left: $gap-0_24x !important;
  }

  &-gap-0_38x {
    padding-left: $gap-0_38x !important;
  }

  &-gap-0_62x {
    padding-left: $gap-0_62x !important;
  }

  &-gap-1_62x {
    padding-left: $gap-1_62x !important;
  }

  &-gap-1x {
    padding-left: $gap-1x !important;
  }

  &-gap-2x {
    padding-left: $gap-2x !important;
  }

  &-gap-3x {
    padding-left: $gap-3x !important;
  }
}

//
// Brain icon on fields
//
.field-and-learn-more {
  display: flex;

  fieldset {
    flex: 0.95;
  }

  .learn-more-icon-right-of-field {
    $icon-size: 1rem; // will not impact the icon size (SVG), will only change how much we move it down
    margin-top: calc(#{$form-item-height} / 2); // go down field height + legend margin bottom (zero at the moment)
    transform: translateY(calc(#{$icon-size} / 2)); // go down half the icon
    margin-left: $gap-0_24x;

    .app-icon {
      cursor: default;
    }
  }
}

.avatar-wrapper {
  position: relative;
  flex-shrink: 0;
  $size: 2.7rem; // 2021-09-16: was 3vw before changing to rem units
  width: $size;
  height: $size;
  border-radius: 50%;

  &.small {
    $size: 1.5vw; // 2021-09-16: was 1.5vw before changing to rem units
    width: $size;
    height: $size;
  }

  &.medium {
    $size: 4.2rem; // 2021-09-16: was 4.5vw before changing to rem units
    width: $size;
    height: $size;
  }

  &.big {
    $size: 5.5rem; // 2021-09-16: was 6vw before changing to rem units
    width: $size;
    height: $size;
  }

  &.header {
    width: calc(#{$header-height} / 1.4);
    height: calc(#{$header-height} / 1.4);

    .theme-v3e & {
      width: calc(#{$header-height} / 2 * 1.19);
      height: calc(#{$header-height} / 2 * 1.19);
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.status-button-style {
  cursor: default !important;
  min-width: 8vw;

  .button {
    height: calc(#{$form-item-height} / 1.5) !important;
  }

  .label {
    font-size: $font-12px !important;
  }
}

.button-mw-25 {
  width: fit-content;
  min-width: 25%;
  display: table !important; // MS Edge (fit-content does not work)
}

.button-mw-50 {
  width: fit-content;
  min-width: 50%;
  display: table !important; // MS Edge (fit-content does not work)
}

.no-wrap {
  white-space: nowrap;
}

//
//
// Tabs (e.g. language selection on nuggets management)
//
//
.tabs {
  .tab-pane {
    outline: none; // remove blue outline when focused
    border-radius: 0 0 $border-radius-6px $border-radius-6px; // bottom corners only
    border: 1px solid var(--dark);
    padding: $gap_0_62x $gap_1x;
    margin-bottom: $gap-1x;
  }

  .nav-tabs {
    // Whole width
    /*border-bottom: 1px solid #dee2e6;*/
    /*border-bottom: 1px solid var(--dark);*/
    border-bottom: none;

    .nav-link {
      color: var(--text-color-1);
      border-color: color.adjust($dark, $lightness: 20%, $space: hsl); // not active / hovered
      border-bottom-color: var(--dark);

      &:hover {
        /*border-color: #e9ecef #e9ecef #dee2e6;*/
        background-image: linear-gradient(to bottom, color.adjust(#51585d, $lightness: 20%, $space: hsl), color.adjust(#44494e, $lightness: 20%, $space: hsl));
        color: color.adjust($theme-v3e-text-color, $lightness: 20%, $space: hsl);

        .app-jgo:not(.theme-v3e) & {
          color: color.adjust($dark, $lightness: 20%, $space: hsl);
        }

        border-color: var(--dark);
      }

      &.active {
        background-image: linear-gradient(to bottom, #51585d, #44494e);
        font-weight: bold;
      }

      &.disabled {
        color: $dark-disabled;
      }
    }
  }

  .nav-tabs-borderless-v1 {
    box-shadow: 0 2px 2px -2px var(--text-color-1); // fine line

    .app-jgo:not(.theme-v3e) & {
      box-shadow: 0 2px 2px -2px var(--dark); // fine line
    }

    & > .nav-item > .nav-link {
      background: none;
      border: none;

      &:hover {
        border: none;
      }

      &.active {
        /*background-image: linear-gradient(to bottom, #51585d, #44494e);*/
        border-radius: 0;
        border-bottom: 3px solid color.adjust($dark, $lightness: 10%, $space: hsl);

        .theme-v3e & {
          border-bottom-color: var(--accent-color) !important;
        }
      }
    }

    &.blue-bottom-border > .nav-item > .nav-link.active {
      border-bottom: 3px solid $lightblue;
    }

    &.no-bottom-border > .nav-item > .nav-link.active {
      border-bottom: none;
    }

    &.no-full-border {
      box-shadow: none;
    }

    &.left-border {
      border-left: 1px solid $lightblue;
      padding-left: $gap-0_38x;
    }
  }

  .tab-content-borderless-v1 > .tab-pane {
    border: none;
    padding: $gap_0_62x 0;
  }

  .tab-content-borderless-v1.left-border > .tab-pane {
    border-left: 1px solid $lightblue;
    padding-left: $gap-0_38x;
  }

  .tab-content-borderless-v1.different-background {
    background-color: color.adjust($theme-v2-columns-background, $lightness: -4%, $space: hsl);

    .theme-v3e & {
      background-color: color.adjust($theme-v3e-columns-background, $lightness: -4%, $space: hsl);
    }

    padding: 0 $gap-0_62x;
  }
}

//
//
// Breadcrumbs
//
//
.breadcrumbs-container {
  margin-bottom: $gap_1x;

  .separator {
    display: inline-block !important;
    padding: 0 calc(#{$gap-0_38x} / 2);
    position: relative;
    bottom: 2px; // compensate the button bottom border
    color: var(--text-color-1);

    .theme-v3e & {
      padding: 0 calc(#{$gap-0_38x} / 1.38);
      color: var(--text-color-1);
      top: 2px; // to center with the buttons
    }
  }

  .item {
    display: inline-block !important;
  }

  .button-wrapper {
    padding: 2px;
  }

  .button {
    height: $gap-1x !important;
  }

  .label {
    font-size: $font-12px !important;
  }
}

.b-toaster {
  z-index: 15000;
}

.border-shadow-container {
  /*border: 1px solid var(--dark);*/
  border-radius: $border-radius-6px;
  padding: $gap-1x;
  box-shadow: 0 0 15px 0 color.adjust($dark, $lightness: 15%, $space: hsl);

  .theme-v3e & {
    @extend .neumorphic-bg-and-shadow-1;
    border-radius: $border-radius-6px;
  }
}

.table-legend-and-value {
  td {
    padding: $gap-0_38x $gap-0_62x 0 0;
    vertical-align: top;

    &:first-child {
      // Fit content
      /*width: 1%;*/
      // don't use this, or else the table will expand to use all available space!
      white-space: nowrap;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .legend {
    font-weight: bold;
    text-align: right;
  }
}

/* Change WebKit autofill white background to another color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--dark) inset !important;
  caret-color: white; // fix the caret color
}

/* Change WebKit autofill text color */
input:-webkit-autofill {
  -webkit-text-fill-color: $light-primary !important;
}

.hidden-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80vh;
  font-size: $font-25px;
  font-weight: bold;
  overflow: hidden;
}

.echart-dark {
  width: 100% !important;
  background-color: var(--dark);
  border-radius: $border-radius-6px;
  overflow: hidden; // to keep the border-radius
  height: 300px; // must be defined, or else the charts will start growing without stopping
}

.toast-messaging-new-message {
  display: flex;
  align-items: center;

  .nickname-message-and-date {
    margin-left: $gap-0_38x;
    /*align-self: flex-start;*/

    .participant-nickname {
      font-weight: bold;
    }

    .message-body {
      max-width: 20vw;
      /*max-height: 2vw; // do not allow the row to grow higher*/
      line-height: 1vw; // avoid cutting the text at the bottom
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.invert-column-padding {
  $negative-margin: $gap-1x * -1;
  margin: $negative-margin $negative-margin 0 $negative-margin;

  .theme-v3e & {
    // do not invert
    margin: 0;
  }

  &.full-width {
    width: calc(100% + (-2 * #{$negative-margin}));
  }
}

red {
  // fire
  color: var(--fire-color);
}

blue {
  // earth
  color: var(--earth-color);
}

green {
  // water
  color: var(--water-color);
}

yellow {
  // air
  color: var(--air-color);
}

.fill-fire,
.symbol-fire-color {
  fill: var(--fire-color);
}

.fill-air,
.symbol-air-color {
  fill: var(--air-color);
}

.fill-water,
.symbol-water-color {
  fill: var(--water-color);
}

.fill-earth,
.symbol-earth-color {
  fill: var(--earth-color);
}

.full-col-bg {
  width: 100%;
  height: 100%;
  max-height: 100vh; // avoid growing the image forever when the other columns are very high

  .full-col-bg-inner-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden; // avoid having the background leaking out, due to the transform scale on some backgrounds
  }

  .theme-v3e & {
    @extend .neumorphic-bg-and-shadow-1;
    border-radius: $border-radius-6px;
    margin-bottom: $gap-1x;

    .full-col-bg-inner-wrapper {
      margin: $gap-0_38x; // same as the impulse block image
      border-radius: $border-radius-6px;
      width: calc(100% - #{$gap-0_38x} * 2) !important;
      height: calc(100% - #{$gap-0_38x} * 2) !important;
    }
  }
}

.bg {
  width: 100%;
  height: 100%;

  &.fire {
    background: url('/img/backgrounds/user-journey/phase-3-IMP_2018_Pic_Lizenz_AdobeStock_32465606.jpg') no-repeat center center;
    background-size: cover;
    transform: scale(1.3) translateY(-11%);
  }

  &.air {
    background: url('/img/backgrounds/user-journey/phase-3-IMP_2018_Pic_Lizenz_AdobeStock_729666.jpeg') no-repeat 80% center;
    background-size: cover;
  }

  &.water {
    background: url('/img/backgrounds/user-journey/phase-3-IMP_2018_Pic_Lizenz_AdobeStock_97059797.jpeg') no-repeat 30% 80%;
    background-size: cover;
    transform: scale(1.3) translateY(-11%);
  }

  &.earth {
    background: url('/img/backgrounds/user-journey/phase-3-IMP_2018_Pic_Lizenz_AdobeStock_199376877.jpeg') no-repeat 40% center;
    background-size: cover;
  }
}

$column-body-neumorphic-inner-container-padding: $gap-0_84x;

.column-body-neumorphic-outer-container {
  .theme-v3e & {
    @extend .neumorphic-bg-and-shadow-1;
    padding: calc(#{$gap-0_24x} * 1.38); // same as the impulse block image
    border-radius: $border-radius-6px;
    //@extend .neumorphic-bg-and-shadow-1-selected;

    &.low {
      @extend .neumorphic-bg-and-shadow-1-selected;
    }

    .column-body-neumorphic-inner-container {
      position: relative;
      // padding can not be applied at the image element, as it is set as a background-image and will not have padding
      padding: $column-body-neumorphic-inner-container-padding;
      //font-size: $font-12px;
      background-color: white;
      border-radius: $border-radius-6px;
      overflow: hidden; // cut image corners if there is a "full-size" image

      &.no-padding {
        padding: 0;
      }

      &.no-background {
        background-color: unset;
      }

      .image-full-column {
        height: 55vh;
        border-radius: $border-radius-6px;
      }

      &.height-62,
      &.height-52,
      &.height-38,
      &.height-24 {
        &.height-62 {
          padding-top: 62%;
        }

        &.height-52 {
          padding-top: 52%;
        }

        &.height-38 {
          padding-top: 38%;
        }

        &.height-24 {
          padding-top: 24%;
        }

        .image-inside-standard-size {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: $border-radius-6px;
        }
      }
    }
  }
}

.neumorphic-outer-container {
  @extend .neumorphic-bg-and-shadow-1;
  padding: calc(#{$gap-0_24x} * 1.38); // same as the impulse block image
  border-radius: $border-radius-6px;

  //position: relative;

  &.low {
    @extend .neumorphic-bg-and-shadow-1-selected;
  }

  .neumorphic-inner-container {
    border-radius: $border-radius-6px;
    //overflow: hidden; // cut image corners if there is a "full-size" image // breaks neumorphic shadow of inner components!

    &.background-white {
      background-color: white;
    }

    &.content-with-light-background,
    .content-with-light-background {
      background-color: white;
      color: var(--text-color-1);
      border-radius: $border-radius-6px;
      font-size: $font-12px;
      padding: $gap-0_84x;

      &.no-background {
        background-color: transparent;
      }

      &.dark-background {
        background-color: var(--text-color-1);
        color: white;
      }
    }
  }
}

.journey-v3-button-below-the-left-column {
  margin-top: $gap-1_62x;
  margin-bottom: $gap-3x;
}

.highlighted-phrase-on-image {
  position: absolute;
  bottom: $gap-1_62x;
  right: 0;
  padding: $gap-0_24x $gap-2x $gap-0_24x $gap-0_62x;

  background-color: var(--accent-color);
  color: #fff;

  font-weight: bold;
  font-size: $font-12px;

  border-top-left-radius: $border-radius-3px;
  border-bottom-left-radius: $border-radius-3px;

  .phrase {
    //height: $gap-1x;
    line-height: $font-12px;
  }
}

.image-full-width-no-margin {
  position: relative; // to move it left (no margin)
  left: calc(#{$column-body-neumorphic-inner-container-padding} * -1); // margin (same as padding of .column-body-neumorphic-inner-container)
  width: calc(100% + #{$column-body-neumorphic-inner-container-padding} * 2); // (same as padding of .column-body-neumorphic-inner-container)

  &.at-bottom {
    margin-bottom: calc(#{$column-body-neumorphic-inner-container-padding} * -1); // margin (same as padding of .column-body-neumorphic-inner-container)
    border-bottom-left-radius: $border-radius-6px;
    border-bottom-right-radius: $border-radius-6px;
    overflow: hidden;
  }
}

.buttons-below-the-column {
  display: flex;
  margin: $gap-0_62x $gap-0_38x $gap-2x $gap-0_38x;

  .button-mw-25 {
    min-width: calc(25% - #{$gap-0_38x});
  }
}

.flex-1 {
  flex: 1;
}

.debug-translation-missing-warning {
  color: red !important;
  font-weight: bold !important;
  font-size: $font-10px !important;
}

.overflow-auto {
  overflow: auto;
}

.accent-color {
  color: var(--accent-color);
}

.same-width {
  flex: 1 1 0;
  min-width: 0;
}

.color {
  &-blue-1 {
    color: $theme-v3e-blue-1;
  }

  &-blue-2 {
    color: $theme-v3e-blue-2;
  }

  &-blue-3 {
    color: $theme-v3e-blue-3;
  }

  &-brown-1 {
    color: $theme-v3e-brown-1;
  }

  &-brown-2 {
    color: $theme-v3e-brown-2;
  }
}
</style>
